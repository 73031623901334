import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { getUser } from '../../../state/createStore';
import Config from '../../../config/config';
import axios from 'axios';
import { Container, Table } from 'react-bootstrap';
import Subrow from '../_helpers/Subrow';
import Subdetails from '../_helpers/Subdetails';
import { Cookies } from 'react-cookie';

function SubscriptionsTab(props) {

    const [userSubs, setUserSubs] = useState([]);
    const [subTypes, setSubTypes] = useState([]);
    const [details, setDetails] = useState(false);
    const [loadingData, setLoadingData] = useState(false);

    let cookies = new Cookies();
    let token = cookies.get('token');

    useEffect(() => {
        getSubTypes();
    }, []);

    function getSubTypes() {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        setLoadingData(true)
        const subTypeFetch = axios.get(`${Config.apiURL}/subscription-types`, config)
        const userSubsFetch = axios.get(`${Config.apiURL}/getUser/${props.user.id}`, config)

        Promise.all([subTypeFetch, userSubsFetch]).then((responses) => {
            setSubTypes(responses[0].data)
            setUserSubs(responses[1].data.subscriptions);
            setLoadingData(false);
        });
    }

    return (
        <Container className="subscriptions-section">
            <div className="sub-edit-title">
                <h4>Előfizetéseim</h4>
            </div>
            <div className="subscription-container">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Státusz</th>
                            <th>Előfizetés kezdete</th>
                            <th>Előfizetés vége</th>
                            <th>Tipus</th>
                            <th>Dokumentumok</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userSubs.map(sub => {
                            let subType = subTypes.find(x => x.id === sub.subscription_type.id)
                            return (
                                <Subrow sub={sub} subType={subType} setDetails={setDetails} details={details} setActiveKey={props.setActiveKey}/>
                            )
                        })}
                    </tbody>
                </Table>
                {loadingData && <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
                    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>}
            </div>
            {details && <Subdetails setDetails={setDetails} {...details} update={getSubTypes} />}
        </Container>
    );
}

const mapStateToProps = (state) => ({
    user: getUser(state)
});

export default connect(
    mapStateToProps,
    null
)(SubscriptionsTab)
