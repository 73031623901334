import React, { useEffect, useState } from 'react';
import Config from '../../config/config';
import axios from 'axios';
import { Cookies } from 'react-cookie';

export default function SubscribeForm(props) {

    const [data, setData] = useState({name:"", email:"", telephone:"", invoice_vatnumber:"", invoice_zipcode:"", invoice_city:"", invoice_street:""});
    const [validationError, setValidationError] = useState({});
    const [error, setError] = useState(false);

    let cookies = new Cookies();
    let token = cookies.get('token');

    const zipcode = props.user.additional_data.company_address_postcode;
    const city = props.user.additional_data.company_address_city;
    const street = props.user.additional_data.company_address_street;

    useEffect(() => {
        setData({...data, 
            email:props.user.email ? props.user.email : "",
            name:props.user.additional_data.company_name ? props.user.additional_data.company_name : "",
            invoice_vatnumber:props.user.additional_data.company_tax_number ? props.user.additional_data.company_tax_number : "",
            telephone:props.user.additional_data.general_phone[0] ? props.user.additional_data.general_phone[0].value : "",
            invoice_zipcode: zipcode ? zipcode : "",
            invoice_city: city ? city : "",
            invoice_street: street ? street : ""
            });
    }, []);


    function payment() {
        let url = Config.salesFormUrl + "/t/r/"+ props.modalSubType.salesform_id + "?";

        Object.entries(data).map(([key, value]) => {
            url += `&${key}=${value}`
        });

        url += "&sub=1";

        window.location.replace(url);
    }

    function validator() {
        let valid = true;

        Object.entries(data).forEach(([key, value]) => {
            if(value === "") {
                valid = false;
                setValidationError(prev => { return {...prev, [key]:"A mező kitöltése kötelező"}})
            }
            else if(key === "email") {
                if(!value.includes("@")) {
                    setValidationError(prev => { return {...prev, [key]:"Az email formátuma nem megfelelő"}})
                }
            }
            else {
                //ez itt még nem jó
                if(validationError.hasOwnProperty(key)) {
                    let temp = {...validationError};
                    delete temp[key];
                    setValidationError(temp);
                }
            }
        })

        if(valid) {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
    
            const bodyParameters = {
                id:props.user.id,
                type:props.modalSubType.id
            };
            
            axios.post(`${Config.apiURL}/subscriptions`, bodyParameters, config).then(response => {
                payment();
            }).catch(err => {
                setError('Jelenleg nem elérhető az előfizetés, kérem próbálja meg késöbb')
                console.log('err', err);
            });
        }
    }

    const handleBackBtn = () => {
        if (props.active !== "free") {
            props.setSubModalShow(false);
        }
        props.setModalSubType(false)
    }

    return(
            <div className="chosen-sub">
                <h3 className="title">Kiválasztott előfizetés:</h3>
                <p className="sub-name">{props.modalSubType.type}</p>
                <div className="payment-container">
                    <div className="step-item">
                        <h3 className="step-title">1. Lépés: Kapcsolati Adatok</h3>
                        <form>
                            <div className="form-group">
                                <label htmlFor="full-name">Teljes név</label>
                                <input type="text" value={data.name} onChange={(evt) => setData({...data, name:evt.target.value})} id="full-name"/>
                                {validationError.hasOwnProperty('name') && <p>{validationError.name} </p>}                                
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">E-mail cím</label>
                                <input type="email" value={data.email} onChange={(evt) => setData({...data, email:evt.target.value})} id="email"/>
                                {validationError.hasOwnProperty('email') && <p>{validationError.email} </p>}                                
                            </div>
                            <div className="form-group">
                                <label htmlFor="tel">Telefonszám</label>
                                <input type="tel" value={data.telephone ? data.telephone : ""} onChange={(evt) => setData({...data, telephone:evt.target.value})} id="tel"/>
                                {validationError.hasOwnProperty('telephone') && <p>{validationError.telephone} </p>}                                
                            </div>
                        </form>
                    </div>
                    <div className="step-item">
                        <h3 className="step-title">2. Lépés: Számlázási Adatok</h3>
                        <form>
                            <div className="form-group">
                                <label htmlFor="billing-name">Számlázási Név</label>
                                <input type="text" value={data.name} onChange={(evt) => setData({...data, name:evt.target.value})} id="billing-name"/>
                                {validationError.hasOwnProperty('name') && <p>{validationError.name} </p>}                                
                            </div>
                            <div className="form-group">
                                <label htmlFor="billing-name">Adószám</label>
                                <input type="text" value={data.invoice_vatnumber} onChange={(evt) => setData({...data, invoice_vatnumber:evt.target.value})} id="tax-num"/>
                                {validationError.hasOwnProperty('invoice_vatnumber') && <p>{validationError.invoice_vatnumber} </p>}                                
                            </div>
                            <div className="form-group">
                                <label htmlFor="zip">Irányítószám</label>
                                <input type="text" value={data.invoice_zipcode} onChange={(evt) => setData({...data, invoice_zipcode:evt.target.value})} id="zip"/>
                                {validationError.hasOwnProperty('invoice_zipcode') && <p>{validationError.invoice_zipcode} </p>}                                
                            </div>
                            <div className="form-group">
                                <label htmlFor="city">Város</label>
                                <input type="text" value={data.invoice_city} onChange={(evt) => setData({...data, invoice_city:evt.target.value})} id="city"/>
                                {validationError.hasOwnProperty('invoice_city') && <p>{validationError.invoice_city} </p>}                                
                            </div>
                            <div className="form-group">
                                <label htmlFor="address">Cím</label>
                                <input type="text" value={data.invoice_street} onChange={(evt) => setData({...data, invoice_street:evt.target.value})} id="address"/>
                                {validationError.hasOwnProperty('invoice_street') && <p>{validationError.invoice_street} </p>}                                
                            </div>
                        </form>
                    </div>
                    <div className="summary">
                        <h3 className="step-title">Rendelés ellenőrzése</h3>
                        <p className="sub-title">{props.modalSubType.type ? props.modalSubType.type : props.modalSubType.type} <span>előfizetés</span></p>
                        <h4 className="total">Végösszeg: <span className="total-price">{props.modalSubType.price ? props.modalSubType.price : props.modalSubType.price}</span> Ft</h4>
                        <p className="vat">Tartalmazza az áfát is!</p>
                        <button className="order-btn" onClick={() =>validator()}>Megrendelem</button>
                        {error && <p>{error}</p>}
                    </div>
                </div>
                <div className="actions d-flex justify-content-between">
                    <button className="back-button" style={{margin:'auto'}} onClick={handleBackBtn}>Vissza</button>
                </div>
            </div>
    );
}