import React, { useState, useEffect } from 'react';
import { Tab, Nav, Col, Row } from 'react-bootstrap';
import DashboardTab from './dashboard_tabs/DashboardTab';
import ProfileTab from './dashboard_tabs/ProfileTab';
import DocumentsTab from './dashboard_tabs/DocumentsTab';
import SubscriptionsTab from './dashboard_tabs/SubscriptionsTab';
import EmbedCodeTab from './dashboard_tabs/EmbedCodeTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faUser, faMoneyCheckAlt, faFolderOpen, faCode } from '@fortawesome/free-solid-svg-icons';

export default function Dashboard(props) {
    const [activeKey, setActiveKey] = useState("dashboard");
    const [subModalShow, setSubModalShow] = useState(false);

    const checkStepsCompleted = () => {
        const user = props.user;
        if (user.profile_data_filled
            && user.document_data_filled
            && user.embed_code_step_completed
            && user.download_step_completed) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        const elementToScroll = document.getElementById("loginBanner");
        if (elementToScroll) {
            elementToScroll.scrollIntoView();
        }
    });

    return (
        <Tab.Container defaultActiveKey="dashboard" activeKey={activeKey} onSelect={(k) => setActiveKey(k)} unmountOnExit={true}>
            <Row className="dashboard-container">
                <Col sm={2} className="nav-section">
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                            <Nav.Link
                                eventKey="dashboard"
                            >
                                <FontAwesomeIcon icon={faList} />
                                Dashboard
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="profile"
                                disabled={!props.user.profile_data_filled}
                            >
                                <FontAwesomeIcon icon={faUser} />
                                Profil
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                disabled={!props.user?.subscriptions?.length || !checkStepsCompleted()}
                                eventKey="subscriptions"
                            >
                                <FontAwesomeIcon icon={faMoneyCheckAlt} />
                                Előfizetéseim
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                disabled={!props.user?.subscriptions?.length || !checkStepsCompleted()}
                                eventKey="documents"
                            >
                                <FontAwesomeIcon icon={faFolderOpen} />
                                Dokumentumok
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                disabled={!props.user?.subscriptions?.length || !checkStepsCompleted()}
                                eventKey="embed_code"
                            >
                                <FontAwesomeIcon icon={faCode} />
                                Beágyazó kód
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col sm={10} className="content-wrapper">
                    <DashboardTabContent user={props.user} setActiveKey={setActiveKey} subModalShow={subModalShow} setSubModalShow={setSubModalShow} />
                </Col>
            </Row>
        </Tab.Container>
    )
}

const DashboardTabContent = (props) => {

    const { subModalShow, setSubModalShow } = props;

    useEffect(() => {
        if (subModalShow === true) {
            props.setActiveKey('dashboard');
        } 
    }, [subModalShow])

    return (
        <Tab.Content >
            <Tab.Pane eventKey="dashboard">
                <DashboardTab user={props.user} setActiveKey={props.setActiveKey} subModalShow={props.subModalShow} setSubModalShow={props.setSubModalShow} />
            </Tab.Pane>
            <Tab.Pane eventKey="profile">
                <ProfileTab user={props.user} setActiveKey={props.setActiveKey} />
            </Tab.Pane>
            <Tab.Pane eventKey="subscriptions">
                <SubscriptionsTab user={props.user} setActiveKey={props.setActiveKey} />
            </Tab.Pane>
            <Tab.Pane eventKey="documents">
                <DocumentsTab user={props.user} setActiveKey={props.setActiveKey} setSubModalShow={props.setSubModalShow} />
            </Tab.Pane>
            <Tab.Pane eventKey="embed_code">
                <EmbedCodeTab user={props.user} setActiveKey={props.setActiveKey} />
            </Tab.Pane>
        </Tab.Content >
    )
}
