import React, { useEffect, useState } from "react";
import { Card, ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { navigate } from "gatsby";

export default function FreeTrialSteps(props) {
    const [loading, setLoading] = useState(true);
    const [stepsCompleted, setStepsCompleted] = useState(0);
    const [steps, setSteps] = useState([
        {
            name: "profile_data",
            completed: false,
            descHeader: "Adatok kitöltése",
            descText: "Töltsd ki az adataidat!",
            path: "/app/profileedit",
            typeName: ""
        },
        {
            name: "document_data",
            completed: false,
            descHeader: "ÁSZF adatok kitöltése",
            descText: "Töltsd ki az ÁSZF adatokat!",
            path: "/app/profileedit",
            typeName: "aszf"
        },
        {
            name: "generate_embed",
            completed: false,
            descHeader: "Embed kód generálás",
            descText: "Generáld le az embed kódodat!",
            path: "/",
            typeName: ""
        },
        {
            name: "download_pdf",
            completed: false,
            descHeader: "Dokumentumok letöltése",
            descText: "Töltsd le a dokumentumaidat!",
            path: "/",
            typeName: ""
        },
        {
            name: "subscribe",
            completed: false,
            descHeader: "Előfizetés",
            descText: "Fizess elő most!",
            path: "/",
            typeName: ""
        },

    ]);

    function checkUserSubStatus() {
        const user = props.user;
        if (user.subscriptions.length) {
            for (let sub of user.subscriptions) {
                if (sub.subscription_type.type !== "Free" && sub.subscription_status.name === "Aktiv") {
                    return true;
                }
            }
        }
        return false;
    }

    useEffect(() => {
        setLoading(true);
        setSteps(prevState => {
            let temp = prevState;
            temp.forEach(step => {
                if (step.name === "profile_data" && props.user.profile_data_filled)
                    step.completed = true;
                if (step.name === "document_data" && props.user.document_data_filled)
                    step.completed = true;
                if (step.name === "data_handling_data" && props.user.data_handling_filled)
                    step.completed = true;
                if (step.name === "generate_embed" && props.user.embed_code_step_completed)
                    step.completed = true;
                if (step.name === "download_pdf" && props.user.download_step_completed)
                    step.completed = true;
                if (step.name === "subscribe" && checkUserSubStatus())
                    step.completed = true;
            });
            const completed = temp.filter(step => step.completed === true).length;
            setStepsCompleted(completed);
            return temp;
        });
        setLoading(false);
    }, [props.active])

    const generateClassName = (step, index) => {
        if (step.completed) return "dashboard-step completed";
        else if (!step.completed && (index === 0 || steps[index - 1].completed)) return "dashboard-step";
        else return "dashboard-step disabled";
    }

    const handleStepClick = (step, index) => {
        if (step.name === "profile_data" || step.name === "document_data") {
            const typeName = step.typeName;
            if (!step.completed && (index === 0 || steps[index - 1].completed))
                navigate(step.path, { state: { typeName } });
        } else if (step.name === "generate_embed" || step.name === "download_pdf") {
            if (!step.completed && (index === 0 || steps[index - 1].completed))
                props.setActiveKey("documents");
        } else {
            props.setSubModalShow(true);
        }
    }

    return (
        <Card>
            <Card.Header>
                {stepsCompleted < steps.length ?
                    <p>Eddig <b>{stepsCompleted}</b> lépést teljesítettél az {steps.length}-ből</p>
                    :
                    <p><b>Gratulálunk!</b> Az összes lépést teljesítetted</p>
                }
                <ProgressBar now={(stepsCompleted / steps.length) * 100} />
            </Card.Header>
            <Card.Body>
                {!loading && steps.map((step, index) => {
                    return (
                        <div className={generateClassName(step, index)} onClick={() => handleStepClick(step, index)}>
                            <FontAwesomeIcon className="check-icon" icon={faCheckCircle} />
                            <div className="dashboard-step-desc">
                                <h5>{step.descHeader}</h5>
                                <p>{step.descText}</p>
                            </div>
                            <FontAwesomeIcon className="arrow-icon" icon={faAngleRight} />
                        </div>
                    )
                })}
            </Card.Body>
        </Card>
    )
}