import React from 'react';
import Layout from '../App/Layout';
import Navbar from "../App/Navbar";
import Footer from "../App/Footer";
import { connect } from "react-redux";
import { getUser, setUser } from '../../state/createStore';
import Dashboard from './Dashboard';

/**
 * Main profile page for logged in users
 * 
 * @param {*} props 
 * @returns 
 */
function Profile(props) {

    return(
        <Layout>
            <Navbar />
            <section className="profile-area">
                <Dashboard user={props.user}/>
            </section>
            <Footer />
        </Layout>
    );
}

const mapStateToProps = (state) => ({
    user:getUser(state)
});

export default connect(
    mapStateToProps, 
    null
)(Profile)
