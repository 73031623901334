import React, { useEffect, useState } from "react";
import { Row, Modal, ModalHeader, ModalBody } from "react-bootstrap";
import PriceBox from '../../Pricing/PriceBox';
import axios from 'axios';
import { Cookies } from 'react-cookie';
import Config from '../../../config/config';
import SubscribeForm from '../SubscirbeForm';
import { graphql, navigate, useStaticQuery } from "gatsby";
import ReactMarkdown from 'react-markdown';
import raw from 'rehype-raw';
import gfm from 'remark-gfm';
import FreeTrialSteps from "../_helpers/FreeTrialSteps";
import PaidSubSteps from "../_helpers/PaidSubSteps";
import moment from "moment";

const query = graphql` {
    strapiDashboard {
        desc
        title
    }
}`

export default function DashboardTab(props) {

    const { subModalShow, setSubModalShow } = props;

    const data = useStaticQuery(query);

    const [subType, selectSubType] = useState(false);
    const [subStatuses, setSubstatuses] = useState([]);
    const [prices, setPrices] = useState(false);
    const [freeDaysLeft, setFreeDaysLeft] = useState(false);
    const [userSubType, setUserSubType] = useState("free");
    const [modalSubType, setModalSubType] = useState(false);

    const location = window.history.state;

    let cookies = new Cookies();
    let token = cookies.get('token');
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    async function getPricingData() {
        const AuthStr = 'Bearer '.concat(token);

        //Get all subscription statuses avalible
        let subStatusResponse = await axios.get(`${Config.apiURL}/subscription-statuses`, { headers: { Authorization: AuthStr } });
        setSubstatuses(subStatusResponse.data)

        //Get pricing data for subscriptions
        let pricingStatus = await axios.get(`${Config.apiURL}/pricing`, { headers: { Authorization: AuthStr } })
        setPrices(pricingStatus.data)
    }

    function subscribeUser() {
        const bodyParameters = {
            id: props.user.id,
            type: subType.id
        };

        axios.post(`${Config.apiURL}/subscriptions`, bodyParameters, config).then(response => {
            setSubModalShow(false);
        }).catch(err => {
            console.log('err', err);
        })
    };

    function handleSubClick(subType) {
        selectSubType(subType);
        setModalSubType(subType);
        if (props.user.profile_data_filled) {
            setSubModalShow(true);
        } else {
            navigate("/app/dashboard_profileedit", { state: { subType: subType } })
        }
    }

    useEffect(() => {
        getPricingData();
        if (location.openSubModal) {
            console.log(location);
            setSubModalShow(true);
            selectSubType(location.subType);
            window.history.replaceState({}, document.title);
        } else {
            selectSubType("free");
        }

        for (let sub of props.user.subscriptions) {
            if (sub.subscription_type.type === "Free") {
                const daysLeft = moment(sub.endDate).diff(new Date(), 'd');
                setFreeDaysLeft(daysLeft);
            }
            if (sub.subscription_type.type !== "Free" && sub.subscription_status.name === "Aktiv") {
                selectSubType(sub.subscription_type);
                setUserSubType(sub.subscription_type);
            }
        }
    }, [])

    return (
        <div className="dashboard-section">

            {/* prices && subType &&
                <Row>
                    <PriceBox
                        page={'/'}
                        onClick={() => selectSubType("free")}
                        colSize={4}
                        title="Ingyenes próbaidőszak"
                        subText=""
                        price={0}
                        options=""
                        buttonAction={() => selectSubType("free")}
                        buttonText={''}
                        loggedin={true}
                        linkPath="#"
                        active={subType}
                        subType="free"
                        daysLeft={freeDaysLeft}
                        userSubType={userSubType}
                    />
                    {prices.priceFeatures.map((feature, idx) => {
                        return (
                            <PriceBox
                                page={'/'}
                                onClick={() => selectSubType(feature.subscription_type)}
                                colSize={4}
                                title={feature.title}
                                subTitle={feature.information}
                                price={feature.price}
                                priceRecurrence={feature.priceRecurrence}
                                subText={feature.packageInformation}
                                options={feature.pacakgeDetails}
                                buttonAction={() => handleSubClick(feature.subscription_type)}
                                buttonText={'ELŐFIZETEK'}
                                loggedin={true}
                                linkPath="#"
                                active={subType}
                                subType={feature.subscription_type}
                                userSubType={userSubType}
                            />
                        );
                    })}
                </Row>
            */}

            <div className="header">
                <h3>{data.strapiDashboard.title}</h3>
                <ReactMarkdown children={data.strapiDashboard.desc} remarkPlugins={[gfm]} rehypePlugins={[raw]} />
            </div>

            

            {subType === "free" ?
                <FreeTrialSteps user={props.user} active={subType === "free"} setActiveKey={props.setActiveKey} setSubModalShow={setSubModalShow} />
                : <PaidSubSteps user={props.user} setActiveKey={props.setActiveKey} active={subType !== "free"} setSubModalShow={setSubModalShow} />
            }


            <Modal show={subModalShow} onHide={() => setSubModalShow(false)} dialogClassName="sub-modal" centered scrollable>
                <ModalHeader closeButton>
                    <h2 className="sub-modal-title">Előfizetések</h2>
                </ModalHeader>

                <ModalBody>
                    <section className="pricing-area pricing-modal pt-100 pb-70 bg-f4f7fe">
                        {((!subType || !modalSubType) && prices) ? <Row>
                            {prices.priceFeatures.map((feature, idx) => {
                                return (
                                    <PriceBox
                                        page={'/'}
                                        colSize={6}
                                        title={feature.title}
                                        subTitle={feature.information}
                                        price={feature.price}
                                        priceRecurrence={feature.priceRecurrence}
                                        subText={feature.packageInformation}
                                        options={feature.pacakgeDetails}
                                        buttonAction={() => setModalSubType(feature.subscription_type)}
                                        buttonText={'KÉREM'}
                                        loggedin={true}
                                        linkPath="#"
                                        active="free"
                                        subType={feature.subscription_type}
                                        userSubType={userSubType}
                                    />
                                );
                            })}
                        </Row>
                            :
                            <SubscribeForm user={props.user} active={subType} subType={subType} modalSubType={modalSubType} setSubModalShow={setSubModalShow} setModalSubType={setModalSubType} subscribeUser={subscribeUser} />}
                    </section>
                </ModalBody>
            </Modal>
        </div>

    )
}