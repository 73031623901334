import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Config from '../../../config/config';
import { Cookies } from 'react-cookie';
import axios from 'axios';
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { Overlay, Tooltip } from "react-bootstrap";
import { navigate } from "gatsby";

export default function EmbedCodeTab(props) {
    const [subTypes, setSubTypes] = useState([]);
    const [showTooltip, setShowTooltip] = useState(false);
    const target = useRef(null);

    let cookies = new Cookies();
    let token = cookies.get('token');

    useEffect(() => {
        updateUserData();
    }, []);

    const copyToClipboard = (event) => {
        target.current = event.target;
        let codeBlock = document.getElementById("embedCode");
        navigator.clipboard.writeText(codeBlock.innerText);
        setShowTooltip(true);
        setTimeout(() => {
            setShowTooltip(false);
        }, 600)
    }

    function updateUserData() {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        axios.get(`${Config.apiURL}/subscription-types`, config).then(response => {
            setSubTypes(response.data)
        }).catch(err => {
            console.log('err', err);
        });
    }

    function getEmbedContent() {
        return props.user.subscriptions.map(sub => {
            let subtype = subTypes.find(x => x.id === sub.subscription_type.id);
            let subId = sub.id;
            if (subtype) {
                return (
                    <div className="embed-section" style={{display: 'block'}}>
                        <div className="embed-content">
                            <h2>{sub.subscription_type.label}</h2>
                            {subtype.document_types.map(type => {
                                return (
                                    <div>
                                        <p>{type.Name}</p>

                                        <div className="code">
                                            <FontAwesomeIcon icon={faCopy} role="button" ref={target} onClick={copyToClipboard} />
                                            <Overlay target={target.current} show={showTooltip} placement="top">
                                                {(props) => (
                                                    <Tooltip id="overlay-example" {...props}>
                                                        Vágólapra másolva!
                                                    </Tooltip>
                                                )}
                                            </Overlay>
                                            <code className="codeblock" id="embedCode">
                                                {`<script id="drwebshop_script">var drwe = document.createElement("iframe"); drwe.src = "${Config.url}/app/embed/${subId}/${props.user.id}/"+window.location.hostname+"/${type.id}";drwe.setAttribute("id", "drwebshop-embed"); drwe.setAttribute("style", "width:100%; height:750px"); drwe.setAttribute("frameBorder", "0"); var sc = document.getElementById("drwebshop_script");sc.parentNode.insertBefore(drwe, sc);</script>`}
                                            </code>
                                        </div>

                                    </div>
                                );
                            })}
                        </div>
                        <div className="my-profile-section">
                            <button onClick={() => props.setActiveKey('documents') }>Dokumentum generálás</button>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div style={{ padding: '50px' }}>
                        <p>Jelenleg nem sikerült betölteni a dokumentumait. </p>
                    </div>
                )
            }
        })
    }

    return (
        getEmbedContent()
    )
}
