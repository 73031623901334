import React, { useEffect } from 'react';
import Layout from '../App/Layout';
import Navbar from "../App/Navbar";
import Footer from "../App/Footer";
import { connect } from "react-redux";
import { getUser } from '../../state/createStore';
import { Container } from 'react-bootstrap';
import { navigate } from "gatsby"

function Payment(props) {

    useEffect(() => {
        setTimeout(() => {
            navigate("/app/profile");
        }, 15000)
    }, [])

    return(
        <Layout>
            <Navbar />
            <Container className="documents">
                <div className="documents-title">
                    <h4>Előfizetés</h4>
                </div>
                {props.status === 'failed' ?
                <div className="failed-payment">
                    <p>Sikertelen fizetés. Kérjük próbálja meg újra. Most visszairányitjuk a Profil oldalra.</p>
                    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
                :
                <div className="success-payment">
                    <p>Sikeres előfizetés. Most visszairányitjuk a Profil oldalra.</p>
                    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>}
            </Container>
            <Footer />
        </Layout>
    );
}

const mapStateToProps = (state) => ({
    user:getUser(state)
});

export default connect(
    mapStateToProps, 
    null
)(Payment)
