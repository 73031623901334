import React, { useEffect, useState, useRef } from 'react';
import Layout from '../App/Layout';
import Navbar from "../App/Navbar";
import Footer from "../App/Footer";
import { Container, Tabs, Tab, Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, navigate } from 'gatsby';
import { faChevronLeft, faChevronRight, faExclamation, faMinus, faPlus, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { getUser, setUser } from '../../state/createStore';
import { connect } from "react-redux";
import Config from '../../config/config';
import { Cookies } from 'react-cookie';
import Tooltip from '../../_helpers/Tooltip';

function ProfileEdit(props) {

    // props received from gatsby navigate()
    const location = window.history.state;
    let cookies = new Cookies();

    const [validationModal, setValidationModal] = useState(false);
    const [errors, setErrors] = useState([]);
    const [feedback, setFeedback] = useState(false);
    const [paragraphs, setParagraphs] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectedTab, setSelectedTab] = useState();
    const [errorFlags, setErrorFlags] = useState([]);
    const [user, setUser] = useState(props.user);

    const [companyData, setCompanyData] = useState(
        {
            company_name: { required: true, value: '', type: 'string', name: 'Céged, vállalkozásod rövid neve', help: 'Ide írd be a webshopodat üzemeltető cég, vállalkozás nevét, aki szerződést köt a vásárlóval. Ez lehet egyéni vállalkozó is pl. Minta Miklós e.v.' },
            email: { required: true, value: user?.email, type: 'string', name: 'A regisztrációhoz használt e-mail', help: 'Nem szerkeszthető mező' },
            company_address_postcode: { type: 'string', name: 'Irányítószám (székhely)', required: true, help: 'Céged, egyéni vállkozásod hivatalos székhelye.' },
            company_address_city: { type: 'string', name: 'Város (székhely)', required: true, help: 'Céged, egyéni vállkozásod hivatalos székhelye.' },
            company_address_street: { type: 'string', name: 'Utca, házszám (székhely)', required: true, help: 'Céged, egyéni vállkozásod hivatalos székhelye.' },
            company_mail_address: { required: false, value: '', type: 'string', name: 'Levelezési cím', help: 'Webshopod levelezését erre a címre kéred.' },
            general_email: { required: true, value: [{ value: "" }], type: 'unique', name: 'E-mail címünk', help: 'Itt add meg az email címeket, amelyeken elérhetnek a vásárlók.' },
            company_tax_number: { required: true, value: '', type: 'string', name: 'Vállalkozásom adószáma', help: 'Adószám megadásánal ügyelj a helyes formátumra!' },
        }
    );

    const [webshopData, setWebShopData] = useState(
        {
            webshop_type: { required: true, value: 'Shoprenter', type: 'list', values: [{ name: "Shoprenter", value: 'Shoprenter' }, { name: "Shoptet", value: 'Shoptet' }, { name: "Unas", value: 'Unas' }, { name: "Shopify", value: 'Shopify' }, { name: "WordPress+WooCommerce", value: 'WordPressWooCommerce' }, { name: "Magento", value: 'Magento' }, { name: "Saját szoftver", value: 'sajatszoftver' }], name: 'Milyen szoftveres megoldást használ a webshopod?', help: 'Itt azt be kell írni, hogy melyik webshop motort használod.' },
            webshop_domain: { required: true, value: '', type: 'string', name: 'Webshopom domain neve', help: 'Ide kell beírnod a webshopod domain nevét. Pl.: https://www.mintaaruhaz.hu' },
            webshop_registration: { required: true, value: 'required', type: 'list', values: [{ name: 'Van és kötelező', value: 'required' }, { name: 'Nincs', value: 'no' }, { name: 'Van, de nem kötelező', value: 'yes_but_not_required' }], name: 'Van regisztráció az oldaladon? A regisztráció kötelező?', help: 'Válaszd ki a honlapodra érvényes lehetőséget.' },
            webshop_submit_button: { required: true, value: '', type: 'string', name: 'Megrendelést beküldő  gombon (Submit gombon) található szöveg', help: 'Ide a gombon szereplő szó szerinti szöveget kell írnod (pl: Megrendelem).' },
            webshop_confirmation: { required: true, value: 'automatic', type: 'list', values: [{ name: 'Kizárólag egy automatikus üzenet, mely a megrendelés visszaigazolását is tartalmazza.', value: 'automatic' }, { name: 'Egy automatikus email és egy második megerősítő email', value: 'automatic_and_confirmation' }, { name: 'Egyéb', value: 'else' }], name: 'Hogyan igazolod vissza a megrendelést?', help: 'Ha a vásárló megrendelését csak egy automatikus emaillel erősíti meg, akkor a KIZÁRÓLAG EGY AUTOMATIKUS ÜZENET lehetőségét jelölje meg.  Ha  a megrendelést egy automatikus email ÉS EGY  majd egy második visszaigazoló emaillel erősíti meg, akkor jelölje meg az EGY AUTOMATIKUS EMAIL ÉS EGY MÁSODIK MEGERŐSÍTŐ EMAIL" lehetőséget. Ha egyik fenti mód sem tükrözi a webshopodban zajló folyamatot, akkor a HARMADIK MÓDSZER lehetőséget válaszd ki és saját szavaiddal írd le a folyamatodat!' },
            webshop_electric_invoice: { required: true, value: 'true', type: 'unique', name: 'Elektronikus számlát bocsátasz ki?', help: 'Itt add meg hogyan igazolod vissza a rendelést.' },
            webshop_confirmation_else: { required: true, value: '', type: 'unique', help: '' },

            webshop_additional_information: { required: false, value: '', type: 'text', name: 'További információ', help: 'Itt van lehetőséged, hogy szabad szöveggel írj bármilyen információt a webshopodról, amit az általunk írt sablonszöveg nem tartalmaz (értelemszerűen nem is tartalmazhat).' },

            hosting_provider_name: { required: true, depend: 'webshop_type', value: '', type: 'string', name: 'Tárhelyszolgáltató neve', help: '' },
            hosting_provider_address: { required: true, depend: 'webshop_type', value: '', type: 'string', name: 'Tárhelyszolgáltató székhelye', help: '' },
            hosting_provider_email: { required: true, depend: 'webshop_type', value: '', type: 'string', name: 'Tárhelyszolgáltató e-mail címe', help: '' },
            hosting_provider_url: { required: true, depend: 'webshop_type', value: '', type: 'string', name: 'Tárhelyszolgáltató weboldal', help: '' },

            traditional_sales: { required: true, value: 'true', type: 'unique', name: 'Fogadsz a webéruházadban telefonos vagy e-mailes megrendelést?', help: 'Válaszd az "igen" opciót, amennyiben van lehetőség nálad telefonon/emailben megrendelést leadni. Érdemes az "igen" opciót választani, ha nem kizárt annak a lehetősége, hogy telefonos/e-mailes megrendelés előfordulhat akár a közeljövőben is.' },

            aszf_effective_date: { required: false, value: '', type: 'date', name: 'Az Általános Szerződási Feltételek hatályba lépési ideje:', help: 'Itt azt a dátumot kell megjeleníteni, amikortól hatályba lép az ÁSZF (azaz végleges formájában felkerül a honlapra és publisholod).' },

            shopping_freefield: { required: false, value: '', type: 'text', name: 'További információ a megrendelésről', help: 'Itt van lehetőséged, hogy szabad szöveggel írj bármilyen információt a megrendelési folyamatról a webshopodban, amit az általunk írt sablonszöveg nem tartalmaz (értelemszerűen nem is tartalmazhat).' },
            elallas_freefield: { required: false, value: '', type: 'text', name: 'További információ az elállásról', help: 'Itt van lehetőséged, hogy szabad szöveggel írj bármilyen információt az elállás folyamatáról a webshopodban, amit az általunk írt sablonszöveg nem tartalmaz (értelemszerűen nem is tartalmazhat).' },
            miscellaneous_freefield: { required: false, value: '', type: 'text', name: 'Egyéb rendelkezések', help: 'Itt van lehetőséged, hogy szabad szöveggel írj bármilyen információt a webshopodról, amit az általunk írt sablonszöveg nem tartalmaz (értelemszerűen nem is tartalmazhat).' },

            /* general_customer_service - switch */
            general_customer_service: { required: true, value: false, type: 'boolean', name: 'Van személyes ügyfélszolgálata a webáruházadnak?', help: 'Ha van fizikai üzleted, akkor azt ide írd be mint panaszkezelés helyét, ügyfélszolgálat elérhetőségeként.' },
            shop_address: { required: false, depend: 'general_customer_service', value: '', type: 'string', name: 'Üzlet címe:' },
            shop_records: { required: false, depend: 'general_customer_service', value: '', type: 'string', name: 'Üzlet nyilvántartása:' }
        }
    );

    const [generalData, setGeneralData] = useState(
        {
            general_phone: { required: true, value: [{ value: "+36 " }], type: 'unique', name: 'Telefonszámunk', help: 'Itt add meg azokat a telefonszámokat, amelyeken el tudnak érni benneteket a vásárlók.' },
            company_registration_authority: { required: true, value: '', type: 'string', name: 'Cégedet, vállalkozásodat nyilvántartásba vevő bíróság vagy hatóság', help: 'Cégek esetében a konkrét cégbíróságot, egyéni vállalkozók esetében a NAV-ot kell megnevezni.' },

            /* company_authority_license - switch */
            company_authority_license: { required: true, value: false, type: 'boolean', name: 'Különleges hatósági engedély', help: 'Minden különleges engedély számot itt meg kell adni.' },
            nebih_number: { required: false, depend: 'company_authority_license', value: '', type: 'string', name: 'NÉBIH engedély szám:', help: '' },
            jewellery_number: { required: false, depend: 'company_authority_license', value: '', type: 'string', name: 'Ékszer forgalmazási engedély szám:', help: '' },

            /* company_physical_shop - switch */
            company_physical_shop: { required: true, value: false, type: 'boolean', name: 'Rendelkezel fizikai üzlettel vagy bemutatóteremmel?' },
            shop_number: { required: false, depend: 'company_physical_shop', value: '', type: 'string', name: 'Fizikai üzlet működési engedély szám:', help: '' },
            physical_address_postcode: { required: false, depend: 'company_physical_shop', value: '', type: 'string', name: 'Fizikai üzlet - irányítószám:', help: '' },
            physical_address_city: { required: false, depend: 'company_physical_shop', value: '', type: 'string', name: 'Fizikai üzlet - város:', help: '' },
            physical_address_street: { required: false, depend: 'company_physical_shop', value: '', type: 'string', name: 'Fizikai üzlet - utca és házszám:', help: '' },

            company_registration_number: { required: true, value: '', type: 'string', name: 'Nyilvántartási szám', help: 'Cég esetében cégjegyzékszám, egyéni vállalkozó esetében a nyilvántartási szám.' },
            company_representative: { required: true, value: '', type: 'string', name: 'A vállalkozás képviselője', help: 'Itt add meg a vállalkozásod hivatalos képviselőjének a nevét, egyéni vállalkozás esetén a vállalkozó nevét.' },
            company_bank_account_number: { required: true, value: [{ value: "" }], type: 'unique', name: 'Bankszámla szám (ok)', help: 'A kereskedelmi tevékenység során a vásárlóktól beérkező összegek átutalását erre a bankszámlára kéred.' },
            general_notification_emails: { required: true, value: [{ value: "" }], type: 'unique', name: 'Hova küldjünk neked DrWebshop rendszerüzenetet?', help: 'Erre az email címre küldünk neked fontos rendszerüzeneteket!' },
        }
    );

    const [atData, setAtData] = useState([
        {
            label: 'Adatfejléc',
            fields: {
                representative_email: { required: true, value: '', type: 'string', field_type: 'email', name: 'Adatkezelő képviselőjének e-mail címe', help: 'Itt add meg a vállalkozásod képviselőjének az e-mail címét.' },
                representative_telephone: { required: true, value: '', type: 'string', name: 'Adatkezelő képviselőjének telefonszáma', help: 'Itt add meg a vállalkozásod képviselőjének telefonszámát.' },
                dpo_question: { required: true, value: false, type: 'boolean', name: 'Van adatvédelmi tisztviselőd?', help: 'Húzd jobbra csúszkát, ha van hivatalosan kinevezett adatvédelmi tisztviselőt, akit bejelentettél az adatvédelmi hatóságnál.' },
                dpo_email: { required: false, depend: 'dpo_question', value: '', type: 'string', field_type: 'email', name: 'Adatvédelmi tisztviselő e-mail címe', help: 'Itt add meg az adatvédelmi tisztviselő nevét és elérhetőségét.' },
                data_protection_email: { required: true, value: '', type: 'string', field_type: 'email', name: 'Adatvédelmi kérdések kezelésére használt e-mail címünk', help: 'Itt add meg azt az e-mail címeteket, melyen a webáruházad látogatói adatvédelemmel kapcsolatos kérdéseket tehetnek fel.' },
                email_confirmation: { required: true, value: false, type: 'boolean', name: 'Kötelezővé teszed az e-mail cím megerősítést?', help: 'Húzd jobbra a csúszkát, ha email megadáskor a honlap fehasználóinak meg kell erősíteniük az email címüket egy a nekik emailben megküldött linkre történő klikkeléssel.' },
            }
        },
        {
            label: 'Érdeklődés, kapcsolatfelvétel',
            fields: {
                form_basic_data: { required: true, value: '', type: 'string', name: 'Érdeklődés/kapcsolatfelvétel során kezelt személyes adatok', help: 'Ide kell beírni azokat az adamezőket, amelyeket a kapcsolatfelvételi űrlapunkon kérünk be, illetve amelyeket tipikusan megadnak az érdeklődők.' },
                form_data_time: { required: true, value: '', type: 'string', name: 'Meddig kezeljük az érdeklődők vagy kapcsolatfelvevők személyes adatait?', help: 'Ezt a mezőt csak kivételes esetben érdemes módosítani.' },
            }
        },
        {
            label: 'Regisztráció',
            fields: {
                reg_data_question: { required: true, value: false, type: 'boolean', name: 'Van-e regisztrációval kapcsolatos adatkezelés?', help: 'Húzd jobbra a csúszkát, ha gyűjtesz regisztrációval kapcsolatos adatokat.' },
                reg_data: { required: true, depend: 'reg_data_question', value: '', type: 'string', name: 'Regisztrált felhasználók személyes adatai', help: 'Ide kell beírni azokat az adamezőket, amelyeket a beregisztrálás során elkérünk, illetve azokat, amelyeket a regisztrált vásárlóhoz rendelünk a későbbiek során.' },
                reg_data_time: { required: true, depend: 'reg_data_question', value: '', type: 'string', name: 'Regisztrációs adatkezelés ideje', help: 'A második mondatban található határidőt (5 év) lehet módosítani ésszerű mértékben, illetve törölni kell a második mondatot, ha nincs inaktivitás esetén törlés.' },
            }
        },
        {
            label: 'Hírlevél, emailes marketing kommunikáció',
            fields: {
                marketing_question: { required: true, value: false, type: 'boolean', name: 'Van-e hírlevél küldéssel kapcsolatos adatkezelés?', help: 'Húzd jobbra a csúszkát, ha gyűjtesz hírlevél címlistát.' },
                marketing_data: { required: true, depend: 'marketing_question', value: '', type: 'string', name: 'Hírlevéllel kapcsolatosan kezelt adatok köre', help: 'Beírtuk a tipikusan kezelt adatköröket. Ha több adatkört is kezelsz, akkor írd ide azokat és bátran töröld a javaslataink közül az általad nem kezelt adatköröket.' },
                marketing_data_time: { required: true, depend: 'marketing_question', value: '', type: 'string', name: 'Hírlevéllel kapcsolatos adatok kezelésének időtartama', help: 'Adtunk egy szövegjavaslatot az adatkezelés időtartamára. Kérjük írd át, ha a webáruházadban másképpen történik, különösen figyelj az adatkezelés időtartamára.' },
            }
        },
        {
            label: 'Rendelés feldolgozása, vásárlás',
            fields: {
                order_data_question: { required: true, value: false, type: 'boolean', name: 'Van-e rendelés feldolgozással kapcsolatos adatkezelés?', help: 'Húzd jobbra a csúszkát, ha kezelsz rendelés teljesítéssel kapcsolatosan adatokat.' },
                order_data: { required: true, depend: 'order_data_question', value: '', type: 'string', name: 'Rendelés teljesítése során kezelt személyes adatok', help: 'Beírtuk a tipikusan kezelt adatköröket. Ha több adatkört is kezelsz, akkor írd ide azokat és bátran töröld a javaslataink közül az általad nem kezelt adatköröket.' },
                order_data_time: { required: true, depend: 'order_data_question', value: '', type: 'string', name: 'Rendelési adatok kezelésének időtartama', help: 'Adtunk egy szövegjavaslatot az adatkezelés időtartamára. Kérjük írd át, ha a webáruházadban másképpen történik, különösen figyelj az adatkezelés időtartamára.' },
            }
        },
        {
            label: 'Pénzvisszafizetéssel kapcsolatos adatkezelés',
            fields: {
                payback_question: { required: true, value: false, type: 'boolean', name: 'Van-e pénzvisszafizetéssel kapcsolatos adatkezelés?', help: 'Húzd jobbra a csúszkát, ha kezelsz pénzvisszafizetéssel kapcsolatos adatokat.' },
                payback_data: { required: true, depend: 'payback_question', value: '', type: 'string', name: 'Pénvisszafizetéssel kapcsolatos személyes adatok köre', help: 'Beírtuk a tipikusan kezelt adatköröket. Ha több adatkört is kezelsz, akkor írd ide azokat és bátran töröld a javaslataink közül az általad nem kezelt adatköröket.' },
                payback_data_time: { required: true, depend: 'payback_question', value: '', type: 'string', name: 'Pénvisszafizetéssel kapcsolatos adatok kezelésének időtartama', help: 'Adtunk egy szövegjavaslatot az adatkezelés időtartamára. Kérjük írd át, ha a webáruházadban másképpen történik, különösen figyelj az adatkezelés időtartamára.' },
            }
        },
        {
            label: 'Számlákkal kapcsolatos adatkezelések',
            fields: {
                billing_data_question: { required: true, value: false, type: 'boolean', name: 'Van-e számlázás során megvalósuló adatkezelés?', help: 'Húzd jobbra a csúszkát, ha számlázással kapcsolatosan kezelsz adatokat.' },
                billing_data: { required: true, depend: 'billing_data_question', value: '', type: 'string', name: 'Milyen szeméyes adatokat kezelünk a számlázás során?', help: 'Beírtuk a tipikusan kezelt adatköröket. Ha több adatkört is kezelsz, akkor írd ide azokat és bátran töröld a javaslataink közül az általad nem kezelt adatköröket.' },
                billing_data_time: { required: true, depend: 'billing_data_question', value: '', type: 'string', name: 'Számlázással kapcsolatos adatok kezelésének időtartama', help: 'FIGYELEM! Ez a szöveg jogszabályi alapokon áll, így átírása előtt mindenképp egyeztess könyvelőddel, vagy jogászainkkal.' },
            }
        },
        {
            label: 'Adatkezelési hozzájárulások igazolásával kapcsolatos adatkezelés',
            fields: {
                log_question: { required: true, value: false, type: 'boolean', name: 'Van-e adatkezelés hozzájárulások igazolásával?', help: 'Húzd jobbra a csúszkát, ha kezelsz adatkezelési hozzájárulások felhasználásával kapcsolatos adatokat.' },
                log_data: { required: true, depend: 'log_question', value: '', type: 'string', name: 'Adatkezelési hozzájárulások igazolásával kapcsolatos adatok köre', help: 'Beírtuk a tipikusan kezelt adatköröket. Ha több adatkört is kezelsz, akkor írd ide azokat és bátran töröld a javaslataink közül az általad nem kezelt adatköröket.' },
                log_data_time: { required: true, depend: 'log_question', value: '', type: 'string', name: 'Adatkezelési hozzájárulások igazolásával kapcsolatos adatkezelés időtartama', help: 'Adtunk egy szövegjavaslatot az adatkezelés időtartamára. Kérjük írd át, ha a webáruházadban másképpen történik, különösen figyelj az adatkezelés időtartamára.' },
            }
        },
        {
            label: 'Panaszügyintézéssel kapcsolatos adatkezelések',
            fields: {
                complaint_question: { required: true, value: false, type: 'boolean', name: 'Van-e panaszügyintézéssel kapcsolatos adatkezelés?', help: 'Húzd jobbra a csúszkát, ha kezelsz panaszügyintézéssel kapcsolatos adatokat.' },
                complaint_data: { required: true, depend: 'complaint_question', value: '', type: 'string', name: 'Panaszügyintézéssel kapcsolatosan kezelt adatok köre', help: 'Beírtuk a tipikusan kezelt adatköröket. Ha több adatkört is kezelsz, akkor írd ide azokat és bátran töröld a javaslataink közül az általad nem kezelt adatköröket.' },
            }
        },
        {
            label: 'Nyereményjátékban való részvétellel kapcsolatos adatkezelések',
            fields: {
                participant_question: { required: true, value: false, type: 'boolean', name: 'Van-e nyereményjátékkal kapcsolatos adatkezelés?', help: 'Húzd jobbra a csúszkát, ha szervezel nyereményjátékot a webáruházadban vagy más felületen.' },
                participant_data: { required: true, depend: 'participant_question', value: '', type: 'string', name: 'Nyereményjátékban való részvétellel kapcsolatosan kezelt adatok köre', help: 'Beírtuk a tipikusan kezelt adatköröket. Ha több adatkört is kezelsz, akkor írd ide azokat és bátran töröld a javaslataink közül az általad nem kezelt adatköröket.' },
                participant_data_time: { required: true, depend: 'participant_question', value: '', type: 'string', name: 'Nyereményjátékban való részvétellel kapcsolatos adatok kezelésének időtartama', help: 'Adtunk egy szövegjavaslatot az adatkezelés időtartamára. Kérjük írd át, ha a webáruházadban másképpen történik, különösen figyelj az adatkezelés időtartamára.' },
                winner_data: { required: true, depend: 'participant_question', value: '', type: 'string', name: 'Nyereményjátékok nyerteseitől bekért adat', help: 'Adtunk egy szövegjavaslatot az adatkezelés időtartamára. Kérjük írd át, ha a webáruházadban másképpen történik, különösen figyelj az adatkezelés időtartamára.' },
            }
        },
        {
            label: 'Utánvét Ellenőr használatával kapcsolatos adatkezelés',
            fields: {
                utanvet_ellenor_question: { required: true, value: false, type: 'boolean', name: 'Használod az Utánvét Ellenőr szolgáltatást a rosszhiszemű csomag át nem vevő vásárlók kiszűrésére?', help: 'Húzd jobbra a csúszkát, ha használod az utánvét ellenőr szolgáltatást.' },
                utanvet_data: { required: true, depend: 'utanvet_ellenor_question', value: '', type: 'string', name: 'Utánvét Ellenőr használatával kapcsolatossan kezelt adatok köre', help: 'Beírtuk a tipikusan kezelt adatköröket. Ha több adatkört is kezelsz, akkor írd ide azokat és bátran töröld a javaslataink közül az általad nem kezelt adatköröket,' },
                utanvet_ellenor_time: { required: true, depend: 'utanvet_ellenor_question', value: '', type: 'string', name: 'Utánvét Ellenőr használatával kapcsolatoss adatok kezelésének időtartama', help: 'Adtunk egy szövegjavaslatot az adatkezelés időtartamára. Kérjük írd át, ha a webáruházadban másképpen történik, különösen figyelj az adatkezelés időtartamára.' },
            }
        },
        {
            label: 'EU-n kívüli adattovábbítások',
            fields: {
                form_eu: { required: true, value: false, type: 'boolean', name: 'EU-n kívüli országba történik-e adattovábbítás?', help: 'Húzd jobbra a csúszkát, ha a webáruházadban kezelt adatokat akárcsak részben is az EU-n kívülre továbbítod.' },
                countries: { required: true, depend: 'form_eu', value: '', type: 'string', name: 'Az alábbi EU-n kívüli ország(ok)ba történik adattovábbítás', help: 'Nevezd meg azokat az EU-n kívüli országokat (pl. India), ahova továbbítasz adatokat.' },
                dataforward_grounds: { required: true, depend: 'form_eu', value: '', type: 'text', name: 'Az EU-n kívüli adattovábbítás jogalapja', help: 'Ha az EU-n kívülre történő adattovábbítás olyan orszgába történik, amely rajta van az EU megfelelőségi határozat listáján, akkor a javasolt szövegünket ne írd át. Ellenkező esetben kérj tájékoztatást az adatfeldolgozódtól, hogy milyen jogalapon történhet adattovábbítás a részére és azt a jogalapot írd be ide.' },
            }
        },
        {
            label: 'Sütikkel kapcsolatos adatkezelések',
            fields: {
                cookie_info: { required: true, value: '', type: 'text', name: 'Egyedi információ a webáruházunkban található sütikről', help: 'Amennyiben szeretnél a webáruházadban található sütikről további, egyedi információt megadni, írd be azt az általunk megadott szöveg mellé.' },
            }
        },
        {
            label: 'Adatfeldolgozók',
            fields: {
                delivery_question: { required: true, value: false, type: 'boolean', name: 'Használsz futárcéget a webshopodban?', help: 'Húzd jobbra a csúszkát, ha nem alkalmazottakkal vagy segítő családtagokkal oldod meg a termékek kiszállítását a vevőkhöz, hanem külső segítséget használsz futárcég formájában.' },
                delivery_name: { required: true, depend: 'delivery_question', value: '', type: 'list', values: [{ name: 'GLS', value: 'gls' }, { name: 'Magyar Posta', value: 'mpl' }, { name: 'DPD', value: 'dpd' }, { name: 'Express One', value: 'expressone' }, { name: 'Egyéb', value: 'egyeb' }], name: 'Válaszd ki az általad használt futárcég(ek)et!', help: 'Válaszd ki a megadottak közül a futárcégedet! Figyelj arra, hogy minden futárcéget nevezz meg, amelyeket használsz. Ha nem találod az előre megadott nevek között az általad használt céget, akkor írd be a futárcéged nevét és adatait az "Egyéb" opció használatával.' },
                delivery_sp: { required: true, value: '', depend: 'delivery_name', type: 'string', name: 'Futárcég neve', help: 'Hozzáadhatsz további futárcéget is a pluszjel használatával.' },
                delivery_seat: { required: true, value: '', depend: 'delivery_name', type: 'string', name: 'Futárcég székhelye', help: '' },
                delivery_email: { required: true, value: '', depend: 'delivery_name', type: 'string', field_type: 'email', name: 'Futárcég e-mail címe', help: '' },

                billing_question: { required: true, value: false, type: 'boolean', name: 'Használsz számlázóprogramot a webshopodban?', help: 'Húzd jobbra a csúszkát, ha használsz egy vagy több külső fél által üzemeltetett számlázó programot. (pl. Számlázz.hu, Billingo)' },
                billing_name: { required: true, depend: 'billing_question', value: '', type: 'list', values: [{ name: 'Billingo', value: 'billingo' }, { name: 'KBOSS.hu', value: 'kboss' }, { name: 'InnVoice', value: 'innvoice' }, { name: 'Egyéb', value: 'egyeb' }], name: 'Válaszd ki az általad használt számlázó program(ok)at!', help: 'Válaszd ki a megadottak közül a számlázó programodat! Ha netán több számlázó programot használsz, akkor figyelj arra, hogy mindegyiket jelöld be. Ha nem találod az előre megadott nevek között az általad használt céget, akkor írd be a számlázó programod nevét és adatait az "Egyéb" opció használatával.' },
                billing_sp: { required: true, value: '', depend: 'billing_name', type: 'string', name: 'Számlázó szoftver üzemeltető cég neve', help: 'Hozzáadhatsz további számlázó szoftver üzemeltető céget is a pluszjel használatával.' },
                billing_seat: { required: true, value: '', depend: 'billing_name', type: 'string', name: 'Számlázó szoftver üzemeltető cég székhelye', help: '' },
                billing_email: { required: true, value: '', depend: 'billing_name', type: 'string', field_type: 'email', name: 'Számlázó szoftver üzemeltető cég e-mail címe', help: '' },

                accountant_question: { required: true, value: false, type: 'boolean', name: 'Külsős könyvelő (cég) szolgáltatásait használod a webáruházadban?', help: 'Húzd jobbra a csúszkát, ha nem saját alkalmazottad a könyvelőd, hanem külsős szolgáltatsáként veszed igénybe a könyvelést.' },
                accountant_name: { required: true, depend: 'accountant_question', value: '', type: 'string', name: 'Könyvelő (cég) neve', help: '' },
                accountant_seat: { required: true, depend: 'accountant_question', value: '', type: 'string', name: 'Könyvelő (cég) székhelye', help: '' },
                accountant_email: { required: true, depend: 'accountant_question', value: '', type: 'string', field_type: 'email', name: 'Könyvelő (cég) e-mail címe', help: '' },

                newsletter_question: { required: true, value: false, type: 'boolean', name: 'Használsz hírlevélküldő szoftvert a webshopodban?', help: 'Húzd jobbra a csúszkát, ha használsz egy vagy több hírlevélküldő szoftvert (pl. MailChimp, Webgalamb) a webáruházadban.' },
                newsletter_name: { required: true, depend: 'newsletter_question', value: '', type: 'list', values: [{ name: 'E.N.S', value: 'ens' }, { name: 'MailerLite', value: 'mailerlite' }, { name: 'MailChimp', value: 'mailchimp' }, { name: 'Egyéb', value: 'egyeb' }], name: 'Válaszd ki az általad használt hírlevélküldő cég neve(ke)t!', help: 'Válaszd ki a megadottak közül a hírlevélküldő cégedet. Figyelj arra, hogy minden hírlevélküldő céget nevezz meg, amelyeket használsz. Ha nem találod az előre megadott nevek között az általad használt céget, akkor írd be a hírlevélküldő céged nevét és adatait az "Egyéb" opció használatával.' },
                newsletter_sp: { required: true, value: '', depend: 'newsletter_name', type: 'string', name: 'Hírlevélküldő cég neve', help: 'Hozzáadhatsz további hírlevél küldő céget.' },
                newsletter_seat: { required: true, value: '', depend: 'newsletter_name', type: 'string', name: 'Hírlevélküldő cég székhelye', help: '' },
                newsletter_email: { required: true, value: '', depend: 'newsletter_name', type: 'string', name: 'Hírlevélküldő cég e-mail címe', help: '' },

                erp_question: { required: true, value: false, type: 'boolean', name: 'Használsz ERP szoftvert a webshopodban?', help: 'Húzd jobbra a csúszkát, ha használsz egy vagy több vállalatirányítási szoftvert ( ERP) a webáruházadban.' },
                erp_name: { required: true, depend: 'erp_question', value: '', type: 'list', values: [{ name: 'CloudERP', value: 'clouderp' }, { name: 'Tharanis', value: 'tharanis' }, { name: 'Egyéb', value: 'egyeb' }], name: 'Válaszd ki az általad használt ERP szoftver szállítókat!', help: 'Válaszd ki a megadottak közül az ERP szoftver szállítódat.. Ha netán többet használsz, akkor tüntesd fel mindegyiket!' },
                erp_sp: { required: true, value: '', depend: 'erp_name', type: 'string', name: 'Vállalatirányítási szoftver szállítójának neve', help: 'Hozzáadhatsz további ERP cégeket is a pluszjel használatával.' },
                erp_seat: { required: true, value: '', depend: 'erp_name', type: 'string', name: 'Vállalatirányítási szoftver szállítójának székhelye', help: '' },
                erp_email: { required: true, value: '', depend: 'erp_name', type: 'string', field_type: 'email', name: 'Vállalatirányítási szoftver szállítójának címe', help: '' },

                fulfillment_question: { required: true, value: false, type: 'boolean', name: 'Használsz-e fulfillment szolgáltatót a webáruházad a logisztikájában?', help: 'Húzd jobbra a csúszkát, ha használsz egy vagy több fulfillment szolgáltatást a webáruházadban.' },
                fulfillment_name: { required: true, depend: 'fulfillment_question', value: '', type: 'list', values: [{ name: 'Boxy', value: 'boxy' }, { name: 'iLogistic', value: 'ilogistic' }, { name: 'WEBSHIPPY', value: 'webshippy' }, { name: 'Complexpress', value: 'complexpress' }, { name: 'Egyéb', value: 'egyeb' }], name: 'Válaszd ki az általad használt fulfillment szolgáltató cég(ek)et!', help: '' },
                fulfillment_sp: { required: true, value: '', depend: 'fulfillment_name', type: 'string', name: 'Fulfillment szolgáltató cég neve', help: 'Hozzáadhatsz további fulfillment szolgáltató cégeket is a pluszjel használatával.' },
                fulfillment_seat: { required: true, value: '', depend: 'fulfillment_name', type: 'string', name: 'Fulfillment szolgáltató cég székhelye', help: '' },
                fulfillment_email: { required: true, value: '', depend: 'fulfillment_name', type: 'string', field_type: 'email', name: 'Fulfillment szolgáltató cég e-mail címe', help: '' },

                payment_question: { required: true, value: false, type: 'boolean', name: 'Használsz online fizetési szolgáltatót a webáruházadban?', help: 'Húzd jobbra a csúszkát, ha használsz egy vagy több online fizetési szolgáltatót a webáruházadban.' },
                payment_name: { required: true, depend: 'payment_question', value: '', type: 'list', values: [{ name: 'Barion', value: 'barion' }, { name: 'OTPMobil', value: 'otpmobil' }, { name: 'OTP Bank Nyrt.', value: 'otpbanknyrt' }, { name: 'B-Payment Zrt.', value: 'bpaymentzrt' }, { name: 'Stripe', value: 'stripe' }, { name: 'Egyéb', value: 'egyeb' }], name: 'Válaszd ki az általad használt online fizetési szolgáltató(k) nevét!', help: 'Válaszd ki a megadottak közül az online fizetési szolgáltató(i)dat! Ha netán többet is használsz, akkor mindegyiket tüntesd fel!' },
                payment_sp: { required: true, value: '', depend: 'payment_name', type: 'string', name: 'Online fizetési szolgáltató cég neve', help: 'Hozzáadhatsz további online fizetési szolgáltatót is a pluszjel használatával.' },
                payment_seat: { required: true, value: '', depend: 'payment_name', type: 'string', name: 'Online fizetési szolgáltató cég székhelye', help: '' },
                payment_email: { required: true, value: '', depend: 'payment_name', type: 'string', field_type: 'email', name: 'Online fizetési szolgáltató cég e-mail címe', help: '' },

                crm_question: { required: true, value: false, type: 'boolean', name: 'Használsz-e valamilyen CRM szoftvert a webáruházadban?', help: 'Húzd jobbra a csúszkát, ha használsz valamilyen CRM rendszert (pl. Salesforce, MINICRM, SalesAutopilot).' },
                crm_name: { required: true, depend: 'crm_question', value: '', type: 'list', values: [{ name: 'MINICRM', value: 'minicrm' }, { name: 'Salesforce', value: 'salesforce' }, { name: 'Egyéb', value: 'egyeb' }], name: 'Válaszd ki az általad használt CRM rendszert!', help: 'Válaszd ki a megadottak közül a CRM szolgáltatódat! Ha netán többet használsz, akkor mindegyiket tüntesd fel!' },
                crm_sp: { required: true, value: '', depend: 'crm_name', type: 'string', name: 'CRM szolgáltató neve', help: '' },
                crm_seat: { required: true, value: '', depend: 'crm_name', type: 'string', name: 'CRM szolgáltató székhelye', help: '' },
                crm_email: { required: true, value: '', depend: 'crm_name', type: 'string', field_type: 'email', name: 'CRM szolgáltató e-mail címe', help: '' },
            }
        },
    ]);

    //FILL OUT DATA FROM BACKEND IF PRESENT
    useEffect(() => {
        loadData();
    }, [])

    async function loadData() {
        try {
            const token = cookies.get('token')
            let userData = user;

            if (!userData) {
                const userResponse = await axios.get(`${Config.apiURL}/users/me`, {
                    headers: {
                        Authorization:
                            `Bearer ${token}`,
                    }
                })
                userData = userResponse.data;
                setUser(userData);
            }

            ////
            const overwritesResponse = await axios.get(`${Config.apiURL}/document-paragraph-overwrites`, {
                headers: {
                    Authorization:
                        `Bearer ${token}`,
                }
            });
            overwritesResponse.data.forEach(p => {
                if(userData?.additional_data?.hasOwnProperty(p.variable_name) && (userData.additional_data[p.variable_name] === "" || userData?.additional_data[p.variable_name] === null)) {
                    userData.additional_data[p.variable_name] = p.paragraph_text
                }
            })

            let tempCompany = { ...companyData };
            let tempWebshop = { ...webshopData };
            let tempGeneral = { ...generalData };
            let tempAt = [ ...atData ];

            Object.entries(userData.additional_data).forEach(([key, value]) => {
                if (companyData.hasOwnProperty(key) && value) {
                    tempCompany[key].value = value
                }

                if (webshopData.hasOwnProperty(key) && value) {
                    tempWebshop[key].value = value
                }

                atData.forEach((data, index) => {
                    if (data.fields.hasOwnProperty(key) && value) {
                        tempAt[index].fields[key].value = value
                    }
                })

                if (generalData.hasOwnProperty(key) && value) {
                    tempGeneral[key].value = value
                }
            });

            setCompanyData(tempCompany);
            setWebShopData(tempWebshop);
            setGeneralData(tempGeneral);
            setAtData(tempAt);

            const inputGroupResponse = await axios.get(`${Config.apiURL}/inputgroups`, {
                headers: {
                    Authorization:
                        `Bearer ${token}`,
                }
            })
            setGroups(inputGroupResponse.data);
            if (location.typeName === "aszf") {
                setSelectedTab("additional-paragraph-edit");
            } else if (location.typeName === "at") {
                setSelectedTab("webshop");
            };
            setSelectedTab(inputGroupResponse.data[0].eventKey)
        } catch (error) {
            console.log(error);
        }
    }

    function setupData() {
        let data = { ...companyData, ...webshopData, ...generalData };
        let refactorerd = {};
        Object.entries(data).forEach(([key, value]) => {
            refactorerd[key] = value.value
        });
        atData.forEach((data, index) => {
            Object.entries(data.fields).forEach(([key, value]) => {
                refactorerd[key] = value.value
            });
        })

        refactorerd['additional_document_data'] = Object.values(paragraphs).map(x => {
            return { paragraph_id: x.variable_name, paragraph_text: x.paragraph_text }
        });

        let additional_data = { ...refactorerd }
        return additional_data;
    }


    function saveProfileData(evt) {
        evt.preventDefault();

        setErrorFlags([]);

        // Request API.
        let token = new Cookies().get('token');

        let dataType = "";
        if (!location.typeName || location.typeName === "") dataType = "profile";
        else if (location.typeName === "aszf") dataType = "document";
        else if (location.typeName === "at") dataType = "data_handling";

        if (dataType === "document" && generalData['company_authority_license'].value === true) {
            if (!generalData['nebih_number'].value && !generalData['jewellery_number'].value) {
                alert('Amennyiben rendelkezel Különleges hatósági engedéllyel kötelező megadnod!');
                return;
            }
        }

        if (dataType === "document" && generalData['company_physical_shop'].value === true) {
            if(
                !generalData['shop_number'].value ||
                !generalData['physical_address_postcode'].value ||
                !generalData['physical_address_city'].value ||
                !generalData['physical_address_street'].value
            ){
                alert('Amennyiben rendelkezel fizikai üzlettel a címét, és engedély számát kötelező megadnod!');
                return;
            }
        }

        // Validate list types
        let isValid = true, fields = {};

        if (location.typeName === "at") {
            fields = { ...atData };
        }

        if (location.typeName === 'aszf') {
            fields = { ...generalData, ...webshopData };
        }

        Object.entries(fields).forEach(([key, field]) => {
            if (field.type !== 'list') return; 
            if (!field.required) return; 
            if (field.value) return;

            // Scroll to element
            const wrongElement = document.querySelector('#' + key);
            if (wrongElement) {
                wrongElement.scrollIntoView();
            }

            // Set red border
            setErrorFlags(errorFlags => ([...errorFlags, key]));
            isValid = false;
        
        });

        if (!isValid) return;


        const data = setupData();
        console.log(data)

        setValidationModal(true);
        axios.post(Config.apiURL + '/updateuser', {
            headers: {
                Authorization:
                    `Bearer ${token}`,
            },
            id: user.id,
            data: data,
            dataType: dataType
        }).then(response => {

            //TODO: itt miért kérem le megint ????
            axios.get(Config.apiURL + '/getUser/' + user.id).then(response => {
                props.setUser(response.data);
                setFeedback('Adatok sikeresen frissitve.');
                setTimeout(() => {
                    setValidationModal(false);
                    navigate(-1);
                }, 3000);

            }).catch(err => {
                setValidationModal(false);
                console.log('err', err);
            });

        }).catch(error => {
            setValidationModal(false);
            // Handle error.
            console.log('An error occurred:', error);
        });
    }


    function addBankAccountNumber(index, value) {
        let temp = { ...generalData };

        temp.company_bank_account_number.value[index] = { value: value };
        setGeneralData(temp);
    }

    function addValueToList(index, value, key, data) {
        let temp = {};
        if (data === 'general') {
            temp = { ...generalData };
        } else if (data === "company") {
            temp = { ...companyData };
        }

        temp[key].value[index] = { value: value };

        if (data === "general") {
            setGeneralData(temp);
        } else if (data === "company") {
            setCompanyData(temp);
        }
    }

    function bankAccountListSetup(add) {
        let temp = { ...generalData };

        if (add) {
            temp.company_bank_account_number.value.push({ value: "" });
            setGeneralData(temp)
        }
        else {
            if (temp.company_bank_account_number.value.length > 1) {
                temp.company_bank_account_number.value.pop();
                setGeneralData(temp)
            }
        }
    }

    function setupListing(add, key, state, setState) {
        let temp = { ...state }
        if (add) {
            temp[key].value.push({ value: key === 'general_phone' ? '+36 ' : '' });
            setState(temp)
        }
        else {
            if (temp[key].value.length > 1) {
                temp[key].value.pop();
                setState(temp)
            }
        }
    }

    function setupCompanyDataForm() {
        return Object.entries(companyData).map(([key, value]) => {
            if (value.type === 'string') {
                return (
                    <div className="form-group" key={key}>
                        <label>{value.name}</label>
                        <span>{value.help}</span>
                        <input required={value.required} style={{ border: errorFlags.includes(key) ? "1px solid red" : "" }} type="text" className="form-control" placeholder="" disabled={key === 'email'} value={companyData[key].value ? companyData[key].value : ''} onChange={(evt) => setCompanyData({ ...companyData, [key]: { ...companyData[key], value: evt.target.value } })} />
                    </div>
                );
            } else if (value.type === 'unique') {

                if (key === 'general_email') {
                    return (
                        <div className="form-group" key={key}>
                            <label>{value.name}</label>
                            <span>{value.help}</span>
                            {[...Array(companyData[key].value.length)].map((x, i) =>
                                <input required={companyData[key].required} style={{ border: errorFlags.includes(key) ? "1px solid red" : "" }} type={key.includes('email') ? 'email' : 'text'} className="form-control message-input" value={companyData[key].value[i].value} placeholder="" onChange={(evt) => addValueToList(i, evt.target.value, key, 'company')} />
                            )
                            }
                            <div className="email-actions">
                                {companyData[key].value.length > 1 &&
                                    <label className="qty-icon" onClick={() => setupListing(false, key, companyData, setCompanyData)}>
                                        <span>Törlés</span>
                                        <FontAwesomeIcon className="qty-icon minus-icon" icon={faMinus} />
                                    </label>}
                                <label className="qty-icon" onClick={() => setupListing(true, key, companyData, setCompanyData)}>
                                    <span>Új</span>
                                    <FontAwesomeIcon className="qty-icon plus-icon" icon={faPlus} />
                                </label>
                            </div>
                        </div>
                    );
                }
            }
            else {
                return (<p key={key}>Hiányzó adat.</p>)
            }
        })
    };

    function setupWebshopData() {
        const webshopTypeDependValues = ["WordPressWooCommerce", "Magento", "sajatszoftver"]
        return Object.entries(webshopData).map(([key, value]) => {
            if (!value.depend || (webshopData[value.depend].value === true || webshopData[value.depend].value === 'egyeb' || webshopTypeDependValues.includes(webshopData[value.depend].value)))
            if (value.type === 'string') {
                return (
                    <div className="form-group" key={key}>
                        <label>{value.name}</label>
                        <span>{value.help}</span>
                        <input required={value.required} style={{ border: errorFlags.includes(key) ? "1px solid red" : "" }} type="text" className="form-control" placeholder="" value={webshopData[key].value ? webshopData[key].value : ''} onChange={(evt) => setWebShopData({ ...webshopData, [key]: { ...webshopData[key], value: evt.target.value } })} />
                    </div>
                );
            } else if (value.type === 'text') {
                return (
                    <div className="form-group" key={key}>
                        <label>{value.name}</label>
                        <span>{value.help}</span>
                        <textarea rows="7" value={webshopData[key].value ? webshopData[key].value : ''} required={value.required} style={{ border: errorFlags.includes(key) ? "1px solid red" : "" }} className="form-control" placeholder="" onChange={(evt) => setWebShopData({ ...webshopData, [key]: { ...webshopData[key], value: evt.target.value } })} >
                        </textarea>
                    </div>
                );
            } else if (value.type === 'date') {
                return (
                    <div className="form-group" key={key}>
                        <label>{value.name}</label>
                        <span>{value.help}</span>
                        <input required={value.required} style={{ border: errorFlags.includes(key) ? "1px solid red" : "" }} type="date" className="form-control" placeholder="" value={webshopData[key].value ? webshopData[key].value : ''} onChange={(evt) => setWebShopData({ ...webshopData, [key]: { ...webshopData[key], value: evt.target.value } })} />
                    </div>
                );
            } else if (value.type === 'list') {
                return (
                    <div className="form-group" key={key} id={key}>
                        <label htmlFor='authory'>{value.name}</label>
                        <span>{value.help}</span>
                        <select name="authory" 
                            id="authory" 
                            value={webshopData[key].value ? webshopData[key].value : 'false'} 
                            onChange={(evt) => {
                                setWebShopData({ ...webshopData, [key]: { ...webshopData[key], value: evt.target.value } });
                                setErrorFlags(errorFlags => errorFlags.filter(errorKey => errorKey !== key));
                            }}
                            style={{ border: errorFlags.includes(key) ? "1px solid red" : "" }}
                            > {/* required={value.required} */}

                            <option value="">Kérlek válassz</option>
                            {value.values.map(option => {
                                return (
                                    <option value={option.value} key={option.name}>{option.name}</option>
                                );
                            })}
                        </select>
                        {webshopData[key].value === 'else' &&
                            <input required={value.required} style={{ border: errorFlags.includes(key) ? "1px solid red" : "" }} type="text" className="form-control" placeholder="" value={webshopData[key+'_else'].value} onChange={(evt) => setWebShopData({ ...webshopData, [key+'_else']: { ...webshopData[key+'_else'], value: evt.target.value } })} />
                        }
                    </div>
                )
            } else if (value.type === 'boolean') {

                /* general_customer_service */
                return (
                    <div className="form-group" key={key} data-key={key}>
                        <label htmlFor='authory'>{value.name}</label><br/>
                        <span>{value.help}</span>
                        <label style={{marginTop: '10px'}} className="boolean-switch">
                            <input checked={value.value === true} onChange={(evt) => setWebShopData({ ...webshopData, [key]: { ...webshopData[key], value: evt.target.checked } })} type="checkbox" />
                            <span className="slider round"></span>
                        </label>
                    </div>
                )
            } else if (value.type === 'unique') {
                if (!key === 'webshop_confirmation_else') {
                    return (
                        <div className="form-group">
                            <label htmlFor='e'>{value.name}</label>
                            <span>{value.help}</span>
                            <select required={value.required} name="e" id="e" value={webshopData[key].value}
                                    onChange={(evt) => setWebShopData({
                                        ...webshopData,
                                        [key]: {...webshopData[key], value: evt.target.value}
                                    })}>
                                <option value="true">Igen</option>
                                <option value="false">Nem</option>
                            </select>
                            {webshopData.webshop_electric_invoice.value === 'true' &&
                                <p className="message-container">A Webáruház elektronikus számlát állít ki Önnek és Ön
                                    az ÁSZF elfogadásával beleegyezik ebbe.</p>}
                        </div>
                    )
                }else if(key === 'traditional_sales') {
                    return (
                        <div className="form-group">
                            <label htmlFor={key}>{value.name}</label>
                            <span>{value.help}</span>
                            <select required={value.required} name={key} id={key} value={webshopData[key].value}
                                    onChange={(evt) => setWebShopData({
                                        ...webshopData,
                                        [key]: {...webshopData[key], value: evt.target.value}
                                    })}>
                                <option value="true">Igen</option>
                                <option value="false">Nem</option>
                            </select>
                            {webshopData.webshop_electric_invoice.value === 'true' &&
                                <p className="message-container">A Webáruház elektronikus számlát állít ki Önnek és Ön
                                    az ÁSZF elfogadásával beleegyezik ebbe.</p>}
                        </div>
                    )
                } 

            }
        })
    };

    function updateAtValue(index, key, value) {
        let updatedValues = [...atData];
        updatedValues[index].fields[key].value = value;

        //Reset the depend fields
        Object.keys(updatedValues[index].fields).forEach((objectKey) => {
            const field = updatedValues[index].fields[objectKey];
            if (field.depend && field.depend === key && field.type === 'list') {
                updatedValues[index].fields[objectKey].value = "";
            }
        });
        setAtData(updatedValues);
    }

    function setupAtData() {
        return atData.map((data, index) => {
            return (
            <fieldset className="at-fieldset">
            <legend>{data.label}</legend>
            {Object.entries(data.fields).map(([key, value]) => {
                if (!value.depend || (data.fields[value.depend].value === true || data.fields[value.depend].value === 'egyeb'))
                if (value.type === 'string') {
                    return (
                        <div className="form-group" key={key}>
                            <label>{value.name}</label>
                            <span>{value.help}</span>
                            <input required={value.required} style={{ border: errorFlags.includes(key) ? "1px solid red" : "" }} type={value.field_type || "text"} className="form-control" placeholder="" value={data.fields[key]?.value ? data.fields[key].value : ''} onChange={(evt) => updateAtValue(index, key, evt.target.value)} />
                        </div>
                    );
                } else if (value.type === 'text') {
                    return (
                        <div className="form-group" key={key}>
                            <label>{value.name}</label>
                            <span>{value.help}</span>
                            <textarea rows="7" value={data.fields[key].value ? data.fields[key].value : ''} required={value.required} style={{ border: errorFlags.includes(key) ? "1px solid red" : "" }} className="form-control" placeholder="" onChange={(evt) => updateAtValue(index, key, evt.target.value)} >
                            </textarea>
                        </div>
                    );
                } else if (value.type === 'date') {
                    return (
                        <div className="form-group" key={key}>
                            <label>{value.name}</label>
                            <span>{value.help}</span>
                            <input required={value.required} style={{ border: errorFlags.includes(key) ? "1px solid red" : "" }} type="date" className="form-control" placeholder="" value={data.fields[key].value ? data.fields[key].value : ''} onChange={(evt) => updateAtValue(index, key, evt.target.value)} />
                        </div>
                    );
                } else if (value.type === 'list') {
                    return (
                        <div className="form-group" key={key}>
                            <label htmlFor='authory'>{value.name}</label>
                            <span>{value.help}</span>
                            <select name="authory" 
                                id="authory"
                                style={{ border: errorFlags.includes(key) ? "1px solid red" : "" }}
                                value={data.fields[key].value ? data.fields[key].value : 'false'} 
                                onChange={(evt) => {
                                    updateAtValue(index, key, evt.target.value)
                                    setErrorFlags(errorFlags => errorFlags.filter(errorKey => errorKey !== key));
                                }}
                                > {/* required={value.required} */}

                                <option value="">Kérlek válassz</option>
                                {value.values.map(option => {
                                    return (
                                        <option value={option.value} key={option.name}>{option.name}</option>
                                    );
                                })}
                            </select>
                        </div>
                    )
                }
                else if (value.type === 'boolean') {
                    return (
                        <div className="form-group">
                            <label htmlFor='authory'>{value.name}</label><br/>
                            <span>{value.help}</span>
                            <label style={{marginTop: '10px'}} className="boolean-switch">
                                <input checked={value.value} onChange={(evt) => updateAtValue(index, key, evt.target.checked)} type="checkbox" />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    )
                }
            })}
            </fieldset>)
        })
    };

    function setupGeneralData() {

        return Object.entries(generalData).map(([key, value]) => {
            if (!value.depend || (generalData[value.depend].value === true || generalData[value.depend].value === 'egyeb'))

            if (value.type === 'string') {
                return (
                    <div className="form-group">
                        <label>{value.name}</label>
                        <span>{value.help}</span>
                        <input required={value.required} style={{ border: errorFlags.includes(key) ? "1px solid red" : "" }} type="text" className="form-control" placeholder="" value={generalData[key].value} onChange={(evt) => setGeneralData({ ...generalData, [key]: { ...generalData[key], value: evt.target.value } })} />
                    </div>
                );
            }

            /* company_authority_license */
            else if (value.type === 'boolean') {
                return (
                    <div className="form-group" key={key} data-key={key}>
                        <label>{value.name}</label><br/>
                        <span>{value.help}</span>
                        <label style={{marginTop: '10px'}} className="boolean-switch">
                            <input checked={value.value === true} onChange={(evt) => setGeneralData({ ...generalData, [key]: { ...generalData[key], value: evt.target.checked } }) } type="checkbox"></input>
                            <span className="slider round"></span>
                        </label>
                    </div>
                )
            }

            else if (value.type === 'unique') {
                if (key === 'general_notification_emails') {
                    return (
                        <div className="form-group" key={key}>
                            <label>{value.name}</label>
                            <span>{value.help}</span>
                            {[...Array(generalData[key].value.length)].map((x, i) =>
                                <input required={value.required} style={{ border: errorFlags.includes(key) ? "1px solid red" : "" }} type={key.includes('email') ? 'email' : 'text'} className="form-control message-input" value={generalData[key].value[i].value} placeholder="" onChange={(evt) => addValueToList(i, evt.target.value, key, 'general')} />
                            )}
                            <div className="email-actions">
                                {generalData[key].value.length > 1 &&
                                    <label className="qty-icon" onClick={() => setupListing(false, key, generalData, setGeneralData)}>
                                        <span>Törlés</span>
                                        <FontAwesomeIcon className="qty-icon minus-icon" icon={faMinus} />
                                    </label>}
                                <label className="qty-icon" onClick={() => setupListing(true, key, generalData, setGeneralData)}>
                                    <span>Új</span>
                                    <FontAwesomeIcon className="qty-icon plus-icon" icon={faPlus} />
                                </label>
                            </div>
                        </div>
                    );
                } else if (key === 'company_bank_account_number') {
                    return (
                        <div className="form-group" key={key}>
                            <label>{value.name}</label>
                            <span>{value.help}</span>
                            {[...Array(generalData[key].value.length)].map((x, i) =>
                                <div className="bank-account-label">
                                    <label htmlFor={x} style={{ display: 'flex' }}>Bankszámla {i + 1}</label>
                                    <input required={value.required} style={{ border: errorFlags.includes(key) ? "1px solid red" : "" }} id={x} type="text" className="form-control bank-account-input" value={generalData[key].value[i].value} placeholder="" onChange={(evt) => addBankAccountNumber(i, evt.target.value)} />
                                </div>
                            )}
                            <div className="email-actions">
                                {generalData[key].value.length > 1 &&
                                    <label className="qty-icon" onClick={() => bankAccountListSetup(false)}>
                                        <span>Törlés</span>
                                        <FontAwesomeIcon className="qty-icon minus-icon" icon={faMinus} />
                                    </label>}
                                <label className="qty-icon" onClick={() => bankAccountListSetup(true)}>
                                    <span>Új</span>
                                    <FontAwesomeIcon className="qty-icon plus-icon" icon={faPlus} />
                                </label>
                            </div>
                        </div>
                    );
                }


                else if (key === 'general_phone') {
                    return (
                        <div className="form-group" key={key}>
                            <label>{value.name}</label>
                            <span>{value.help}</span>
                            {[...Array(generalData[key].value.length)].map((x, i) =>
                                <input style={{ border: errorFlags.includes(key) ? "1px solid red" : "" }} type={key.includes('email') ? 'email' : 'text'} className="form-control message-input" value={generalData[key].value[i].value} placeholder="" onChange={(evt) => addValueToList(i, evt.target.value, key, 'general')} />
                            )}
                            <div className="email-actions">
                                {generalData[key].value.length > 1 &&
                                    <label className="qty-icon" onClick={() => setupListing(false, key, generalData, setGeneralData)}>
                                        <span>Törlés</span>
                                        <FontAwesomeIcon className="qty-icon minus-icon" icon={faMinus} />
                                    </label>}
                                <label className="qty-icon" onClick={() => setupListing(true, key, generalData, setGeneralData)}>
                                    <span>Új</span>
                                    <FontAwesomeIcon className="qty-icon plus-icon" icon={faPlus} />
                                </label>
                            </div>
                        </div>
                    );
                }
            }
        });
    }

    function paragraphEdit(id) {
        return (
            <div className="paragraph-edit">
                {Object.entries(paragraphs).map(([key, value]) => {
                    if (value?.inputgroup?.id === id) {
                        return (
                            <div key={key}>
                                <label>{value.title}</label>
                                <textarea type="text" rows="7" className="form-control" placeholder="" value={value.paragraph_text} onChange={(evt) => setParagraphs({ ...paragraphs, [key]: { ...paragraphs[key], paragraph_text: evt.target.value } })} />
                            </div>
                        );
                    }
                })}
            </div>
        )
    }

    function closeValidationModal() {
        setValidationModal(false);
        setErrors([]);
        setFeedback(false);
    }

    function checkInvalidDataInTab(title, ref) {
        let temp = { companyData: companyData, generalData: generalData, webshopData: webshopData };
        let errorInData = false;
        errorFlags.forEach(error => {
            if (temp[ref]) {
                if (Object.keys(temp[ref]).includes(error)) {
                    errorInData = true;
                }
            }
        })

        if (errorInData) {
            return (<p>{title} <Tooltip text={"Hiba a kitöltött adatok között."}><FontAwesomeIcon style={{ marginLeft: '5px' }} color="#ff5757" className="" icon={faExclamation} /></Tooltip></p>)
        }
        else {
            return (<p>{title}</p>)
        }
    }

    const myRef = useRef(false);

    useEffect(() => {
        let teszt = document.querySelector('.profile-edit-tabs');
        myRef.current = teszt;
    }, [])

    /**
     * Forms for ÁSZF and Adatkezelés
     * TODO: The values here are hardcoded, so this solution is not too elegant.
     * Maybe find a better solution later
     */
    function getTabs() {

        if (location.typeName === "at") {
            return (
                <Tabs
                    style={{ width: "95%", margin: "auto", paddingBottom: 0 }}
                    defaultActiveKey="additional-paragraph-edit"
                    className="profile-edit-tabs"
                    ref={myRef}
                >
                    <Tab
                        eventKey={'additional-paragraph-edit'}
                        key={'additional-paragraph-edit'}
                        title={checkInvalidDataInTab('Adatkezelési adatok', 'additionalInformation')}
                    >
                        {setupAtData()}
                    </Tab>
                </Tabs>
            );
        } else if (location.typeName === "aszf") {
            return (
                <Tabs
                    style={{ width: "95%", margin: "auto", paddingBottom: 0 }}
                    defaultActiveKey="webshop"
                    className="profile-edit-tabs"
                    ref={myRef}
                >
                    <Tab
                        eventKey={"webshop"}
                        key={"webshop"}
                        title={checkInvalidDataInTab(
                            "Weboldalinformációk",
                            "webshopData"
                        )}
                    >
                        {setupGeneralData()}
                        {setupWebshopData()}
                    </Tab>
                </Tabs>
            );
        } else {
            return (
                <Tabs
                    style={{ width: "95%", margin: "auto", paddingBottom: 0 }}
                    defaultActiveKey=""
                    activeKey={selectedTab}
                    onSelect={(k) => setSelectedTab(k)}
                    className="profile-edit-tabs"
                    ref={myRef}
                >
                    {groups.map((group) => {
                        if (group.ref === "companyData") {
                            return (
                                <Tab
                                    eventKey={group.eventKey}
                                    key={group.eventKey}
                                    title={checkInvalidDataInTab(group.name, group.ref)}
                                >
                                    {group.ref === "companyData" ? setupCompanyDataForm() : null}
                                </Tab>
                            );
                        }
                    })}
                </Tabs>
            );
        }
    }

    function getHeading() {
        if (location.typeName === "aszf") {
            return <h4>ÁSZF adatok szerkesztése</h4>;
        } else if (location.typeName === "at") {
            return <h4>Adatkezelési adatok szerkesztése</h4>;
        } else {
            return <h4>Profil adatok szerkesztése</h4>;
        }
    }

    

    return (
        <Layout>
            <Navbar />
            <Container className="profile-edit-component">
                <div className="profile-edit-title">
                    <Button className="back-button" onClick={() => navigate(-1)}>Vissza</Button>
                    {getHeading()}
                </div>
                <div className="profile-edit-container">
                    <form onSubmit={saveProfileData}>
                        {getTabs()}
                        <div className="profile-edit-button-group" style={{ marginTop: '20px' }}>
                            <button className="save-button" style={{ width: '100%' }} type="submit">Mentés</button>
                        </div>
                    </form>
                </div>
            </Container>
            <Footer />
            <Modal show={validationModal} onHide={() => closeValidationModal()} backdrop="static">
                <Modal.Body>
                    <div className="feedback-modal">
                        <p>Adatok ellenőrzése</p>
                        {!errors.length && <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}
                        {errors.length ? <div>
                            {errors.map(error => <p>{error}</p>)}
                            <button className="save-button" onClick={() => closeValidationModal()}>Vissza</button>
                        </div> : null}
                        {feedback && <p>{feedback}</p>}
                    </div>
                </Modal.Body>
            </Modal>
        </Layout>
    );
}

const mapStateToProps = (state) => ({
    user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
    setUser: (user) => {
        dispatch(setUser(user))
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileEdit)
