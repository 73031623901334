import React, { useEffect, useState, useRef } from 'react';
import Layout from '../App/Layout';
import Navbar from "../App/Navbar";
import Footer from "../App/Footer";
import { Container, Tabs, Tab, Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, navigate } from 'gatsby';
import { faChevronLeft, faChevronRight, faExclamation, faMinus, faPlus, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { getUser, setUser } from '../../state/createStore';
import { connect } from "react-redux";
import Config from '../../config/config';
import { Cookies } from 'react-cookie';
import Tooltip from '../../_helpers/Tooltip';

function DashboardProfileEdit(props) {

    // props received from gatsby navigate()
    const location = window.history.state;
    let cookies = new Cookies();

    const [validationModal, setValidationModal] = useState(false);
    const [errors, setErrors] = useState([]);
    const [feedback, setFeedback] = useState(false);
    const [paragraphs, setParagraphs] = useState([]);
    const [groups, setGroups] = useState([]);
    const [errorFlags, setErrorFlags] = useState([]);
    const [selectedTab, setSelectedTab] = useState();

    //Address data
    const [companyAdress, setCompanyAddress] = useState({ postal: "", city: "", street: "" })

    const [companyData, setCompanyData] = useState(
        {
            company_name: { required: true, value: '', type: 'string', name: 'Céged, vállalkozásod rövid neve', help: 'Ide írd be a webshopodat üzemeltető cég, vállalkozás nevét, aki szerződést köt a vásárlóval. Ez lehet egyéni vállalkozó is pl. Minta Miklós e.v.' },
            email: { required: true, value: props.user.email, type: 'string', name: 'A regisztrációhoz használt e-mail', help: 'Nem szerkeszthető mező' },
            company_address: { required: true, value: '', type: 'string', name: 'Céged, vállalkozásod székhelye', help: 'Céged, egyéni vállkozásod hivatalos székhelye.' },
            company_mail_address: { required: false, value: '', type: 'string', name: 'Levelezési cím', help: 'Webshopod levelezését erre a címre kéred.' },
            general_email: { required: true, value: [{ value: "" }], type: 'unique', name: 'E-mail címünk', help: 'Itt add meg az email címeket, amelyeken elérhetnek a vásárlók.' },
            company_tax_number: { required: true, value: '', type: 'string', name: 'Vállalkozásom adószáma', help: 'Adószám megadásánal ügyelj a helyes formátumra!' },
        }
    );


    //FILL OUT DATA FROM BACKEND IF PRESENT
    useEffect(() => {
        if (props.user.additional_data_filled) {
            let tempCompany = { ...companyData };


            Object.entries(props.user.additional_data).map(([key, value]) => {
                if (key === 'company_address' && value) {
                    const address = value.split(',');
                    setCompanyAddress({ postal: address[0], city: address[1], street: address[2] })
                }

                if (companyData.hasOwnProperty(key) && value) {
                    if (key === "general_email" && !value.length) {
                        return;
                    }

                    tempCompany[key].value = value
                }
            });

            setCompanyData(tempCompany);
        }

        let token = cookies.get('token')

        axios.get(`${Config.apiURL}/inputgroups`, {
            headers: {
                Authorization:
                    `Bearer ${token}`,
            }
        }).then(response => {
            setGroups(response.data);
            setSelectedTab(response.data[0].eventKey);
        }).catch(err => {
            console.log('er', err)
        });

        axios.get(`${Config.apiURL}/document-paragraph-overwrites`, {
            headers: {
                Authorization:
                    `Bearer ${token}`,
            }
        }).then(response => {
            let temp = {};
            response.data.forEach(p => {
                let updated = props.user.additional_document_data.find(x => x.paragraph_id === p.variable_name)
                if (updated) {
                    temp[p.id] = { ...p, 'paragraph_text': updated.paragraph_text };
                } else {
                    temp[p.id] = p;
                }
            })
            setParagraphs(temp);
        });
    }, [])

    function setupData() {
        let data = { ...companyData };
        let refactorerd = {};
        Object.entries(data).forEach(([key, value]) => {
            refactorerd[key] = value.value
        });


        refactorerd['additional_document_data'] = Object.values(paragraphs).map(x => {
            return { paragraph_id: x.variable_name, paragraph_text: x.paragraph_text }
        });

        let additional_data = { ...refactorerd, company_address: `${companyAdress.postal},${companyAdress.city},${companyAdress.street}` }
        return additional_data;
    }

    function saveProfileData(evt) {
        evt.preventDefault();
        setValidationModal(true);

        // Request API.
        let token = new Cookies().get('token');

        let dataType = "";
        if (!location.typeName || location.typeName === "") dataType = "profile";

        axios.post(Config.apiURL + '/updateuser', {
            headers: {
                Authorization:
                    `Bearer ${token}`,
            },
            id: props.user.id,
            data: setupData(),
            dataType: dataType
        }).then(response => {
            //TODO: itt miért kérem le megint ????
            axios.get(Config.apiURL + '/getUser/' + props.user.id).then(response => {
                props.setUser(response.data);
                setFeedback('Adatok sikeresen frissitve.');
                setTimeout(() => {
                    setValidationModal(false);
                    navigate("/app/profile", { state: { openSubModal: true, subType: location.subType } });
                }, 3000);

            }).catch(err => {
                console.log('err', err);
            });

        }).catch(error => {
            // Handle error.
            console.log('An error occurred:', error);
        });
    }

    function addValueToList(index, value, key, data) {
        let temp = { ...companyData };
        temp[key].value[index] = { value: value };
        setCompanyData(temp);
    }

    function setupListing(add, key, state, setState) {
        let temp = { ...state }
        if (add) {
            temp[key].value.push({ value: key === 'general_phone' ? '+36 ' : '' });
            setState(temp)
        }
        else {
            if (temp[key].value.length > 1) {
                temp[key].value.pop();
                setState(temp)
            }
        }
    }

    function setupCompanyDataForm() {
        return Object.entries(companyData).map(([key, value]) => {
            if (key === "company_address") {
                return (
                    <div className="form-group">
                        <label>{value.name}</label>
                        <span>{value.help}</span>
                        <input required type="text" className="form-control" placeholder="Irányítószám" value={companyAdress.postal} onChange={(evt) => setCompanyAddress({ ...companyAdress, postal: evt.target.value })} />
                        <input required style={{ marginTop: "10px" }} type="text" className="form-control" placeholder="Város" value={companyAdress.city} onChange={(evt) => setCompanyAddress({ ...companyAdress, city: evt.target.value })} />
                        <input required style={{ marginTop: "10px" }} type="text" className="form-control" placeholder="Utca, házszám" value={companyAdress.street} onChange={(evt) => setCompanyAddress({ ...companyAdress, street: evt.target.value })} />
                    </div>
                );
            }
            if (value.type === 'string') {
                return (
                    <div className="form-group" key={key}>
                        <label>{value.name}</label>
                        <span>{value.help}</span>
                        <input required={value.required} style={{ border: errorFlags.includes(key) ? "1px solid red" : "" }} type="text" className="form-control" placeholder="" disabled={key === 'email'} value={companyData[key].value ? companyData[key].value : ''} onChange={(evt) => setCompanyData({ ...companyData, [key]: { ...companyData[key], value: evt.target.value } })} />
                    </div>
                );
            } else if (value.type === 'unique') {

                if (key === 'general_email') {
                    return (
                        <div className="form-group" key={key}>
                            <label>{value.name}</label>
                            <span>{value.help}</span>
                            {[...Array(companyData[key].value.length)].map((x, i) =>
                                <input required={companyData[key].required} style={{ border: errorFlags.includes(key) ? "1px solid red" : "" }} type={key.includes('email') ? 'email' : 'text'} className="form-control message-input" value={companyData[key].value[i].value} placeholder="" onChange={(evt) => addValueToList(i, evt.target.value, key, 'company')} />
                            )
                            }
                            <div className="email-actions">
                                {companyData[key].value.length > 1 &&
                                    <label className="qty-icon" onClick={() => setupListing(false, key, companyData, setCompanyData)}>
                                        <span>Törlés</span>
                                        <FontAwesomeIcon className="qty-icon minus-icon" icon={faMinus} />
                                    </label>}
                                <label className="qty-icon" onClick={() => setupListing(true, key, companyData, setCompanyData)}>
                                    <span>Új</span>
                                    <FontAwesomeIcon className="qty-icon plus-icon" icon={faPlus} />
                                </label>
                            </div>
                        </div>
                    );
                }
            }
            else {
                return (<p>Hiányzó adat.</p>)
            }
        })
    };

    function closeValidationModal() {
        setValidationModal(false);
        setErrors([]);
        setFeedback(false);
    }

    function checkInvalidDataInTab(title, ref) {
        let temp = { companyData: companyData };
        let errorInData = false;
        errorFlags.forEach(error => {
            if (temp[ref]) {
                if (Object.keys(temp[ref]).includes(error)) {
                    errorInData = true
                }
            }
        })

        if (errorInData) {
            return (<p>{title} <Tooltip text={"Hiba a kitöltött adatok között."}><FontAwesomeIcon style={{ marginLeft: '5px' }} color="#ff5757" className="" icon={faExclamation} /></Tooltip></p>)
        }
        else {
            return (<p>{title}</p>)
        }
    }

    const myRef = useRef(false);

    useEffect(() => {
        let teszt = document.querySelector('.profile-edit-tabs');
        myRef.current = teszt;
    }, [])

    /**
     * Forms for ÁSZF and Adatkezelés
     * TODO: The values here are hardcoded, so this solution is not too elegant.
     * Maybe find a better solution later
     */
    function getTabs() {
        return (
            <Tabs
                style={{ width: "95%", margin: "auto", paddingBottom: 0 }}
                defaultActiveKey=""
                activeKey={selectedTab}
                onSelect={(k) => setSelectedTab(k)}
                className="profile-edit-tabs"
                ref={myRef}
            >
                {groups.map((group) => {
                    if (group.ref === "companyData") {
                        return (
                            <Tab
                                eventKey={group.eventKey}
                                key={group.eventKey}
                                title={checkInvalidDataInTab(group.name, group.ref)}
                            >
                                {group.ref === "companyData" ? setupCompanyDataForm() : null}
                            </Tab>
                        );
                    }
                })}
            </Tabs>
        );
    }

    function getHeading() {
        return <h4>Profil adatok szerkesztése</h4>;
    }

    return (
        <Layout>
            <Navbar />
            <Container className="profile-edit-component">
                <div className="profile-edit-title">
                    <Button className="back-button" onClick={() => navigate(-1)}>Vissza</Button>
                    {getHeading()}
                </div>
                <div className="profile-edit-container">
                    <form onSubmit={saveProfileData}>
                        {getTabs()}
                        <div className="profile-edit-button-group" style={{ marginTop: '20px' }}>
                            <button className="save-button" style={{ width: '100%' }} type="submit">Mentés</button>
                        </div>
                    </form>
                </div>
            </Container>
            <Footer />
            <Modal show={validationModal} onHide={() => closeValidationModal()} backdrop="static">
                <Modal.Body>
                    <div className="feedback-modal">
                        <p>Adatok ellenőrzése</p>
                        {!errors.length && <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}
                        {errors.length ? <div>
                            {errors.map(error => <p>{error}</p>)}
                            <button className="save-button" onClick={() => closeValidationModal()}>Vissza</button>
                        </div> : null}
                        {feedback && <p>{feedback}</p>}
                    </div>
                </Modal.Body>
            </Modal>
        </Layout>
    );
}

const mapStateToProps = (state) => ({
    user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
    setUser: (user) => {
        dispatch(setUser(user))
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardProfileEdit)
