import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Modal } from 'react-bootstrap';
import moment from 'moment';
import Config from '../../../config/config';
import axios from 'axios';
import { Cookies } from 'react-cookie';
import { navigate } from 'gatsby-link';

const actions = {
	CANCEL: 0,
	UPGRADE: 1,
	RESUB: 2,
}

export default function Subdetails({sub, subType, setDetails, update}) {

    const [show, setShow] = useState(false);
    const [feedback, setFeedback] = useState(false);
    const [enableLoader, setEnableLoader] = useState(false);

    console.log(sub);

    let cookies = new Cookies();
    let token = cookies.get('token');

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    function getModalText() {
        if(show.action === actions.CANCEL) {
            return(
                <p className="confirm">Biztosan megszakitja az előfizetést ?</p>
            );
        }else if(show.action === actions.UPGRADE) {
            return(
                <p className="confirm">Válassza ki a lehetőségeket:</p>
            );
        }else {
            return(
                <p className="confirm">Biztosan újrainditja az előfizetését ? A Bankártyája terhelve lesz</p>
            );
        }
    };

    function handleAction() {
        setEnableLoader(true);

        setTimeout(() => {
            setEnableLoader(false);
        }, 2000)

        if(show.action === actions.CANCEL) {
            cancelSub();
        }else {
            activateSub();
        }

    }

    function activateSub() {
        axios.get(`${Config.apiURL}/trid/${sub.id}`, config).then(response => {
            window.location.replace(Config.salesFormUrl + '/t/r/repay/' + response.data)
        }).catch(err => {
            console.log('err', err);
        })
    }

    function cancelSub() {
        let token = cookies.get('token');
        
        let bodyParameters = {
            id:sub.id
        }
        //TODO ez úgy ahogy van szar

        axios.post(Config.apiURL + '/subscriptions/cancel',
          bodyParameters,
          config
        ).then(res => {
            if(res.status === 200) {
                update();
                axios.get(`${Config.apiURL}/token/${sub.id}`, config).then(response => {
                    window.location.replace(Config.salesFormUrl + '/index/cancel/' + response.data)
                }).catch(err => {
                    console.log('err', err);
                })
            }
        }).catch(err => {
            console.log('err', err)
        });
    }

    function checkActiveSub() {
        if(sub.subscription_status.unique_id === "expired") {
            return false
        }
        else {
            return true
        }
    }

    function checkSub() {
        if(sub.subscription_status.unique_id === "active" && sub.subscription_type.unique_id !== "free") {
            return false;
        }
        else {
            return true;
        }
    }

    return(
        <div className="subdetails-container">
            <FontAwesomeIcon className="qty-icon minus-icon" style={{cursor:'pointer'}} className="help-icon" onClick={() => setDetails(false)} icon={faTimes}/>
            <p className="title">Előfitetés részletei</p>
            {sub ? <Row>
                <Col>
                <ul>
                    <li><span>Státusz: </span>{sub.subscription_status.name}</li>
                    <li><span>Előfizetés kezdete : </span>{moment(sub.startDate).format('YYYY-MM-DD hh:mm')}</li>
                    <li><span>Előfizetés vége : </span>{moment(sub.endDate).format('YYYY-MM-DD hh:mm')}</li>
                    <li><span>Tipus: </span>{sub.subscription_type.label}</li>
                    <br></br>
                    <p>Fizetések</p>
                    {sub.subscription_type.unique_id === "free" ?
                        <div>
                            <p>Ingyenes próbaidőszak, nem volt szükség a fizetésre.</p>
                        </div>
                        :
                        <div>
                            {sub.payments ? sub.payments.map(payment => {
                            return(<div style={{margin:'10px'}}>
                                    <li><span>Email:</span>  {payment.email}</li>
                                    <li><span>Időpont:</span>  {moment(payment.created_at).format('YYYY-MM-DD hh:mm')}</li>

                            </div>)
                        }) : null}
                    </div>}
                </ul>  
                </Col>
            </Row>
            :
                <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            }
            <Row className="action-container">
                <Col sm={12} lg={6}>
                    <button disabled={checkSub()} onClick={() => setShow({title:'Lemondás', action:actions.CANCEL})} >Lemondás</button>
                    <p>Ha lemondja az előfizetést megszünik a dokumentumai automatikus updatelése, és a beágyazható kód használatához való jogosultsága</p>
                </Col>
                <Col sm={12} lg={6}>
                    <button disabled={checkActiveSub()} onClick={() => setShow({title:'Meghosszabitás', action:actions.RESUB})}>Meghosszabitás</button>
                    <p>Meghosszabithatja lejárt előfizetését</p>
                </Col>
            </Row>

            <Modal show={show} onHide={() => setShow(false)} dialogClassName="feedback-modal">
                <Modal.Header closeButton>
                    <Modal.Title>{show.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {getModalText()}
                    {enableLoader && <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}
                    {feedback && <p>{feedback}</p>}
                </Modal.Body>
                <Modal.Footer>
                {(!enableLoader && !feedback) &&
                    <Row>
                        <Col>
                            <button className="default-button" variant="secondary" onClick={() => setShow(false)}>Mégse</button>
                        </Col>
                        <Col>
                            <button className="default-button" variant="primary" onClick={() => handleAction()}>Igen</button>
                        </Col>
                    </Row>}

                    {feedback && <Col>
                        <button className="default-button" onClick={() => {
                            setShow(false);
                            setFeedback(false);
                        }}>Bezárás</button>
                    </Col>}
                </Modal.Footer>
            </Modal>
        </div>
    );

}