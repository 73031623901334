import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { getUser, setUser } from '../../../state/createStore';
import { Cookies } from 'react-cookie';
import Config from '../../../config/config';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { navigate } from "gatsby";
import { user_data } from "../../../_helpers/userdata";

function DocumentsTab(props) {

    const [subTypes, setSubTypes] = useState([]);
    const [preview, setPreview] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [isDataMissing, setIsDataMissing] = useState(true);
    const [userSubStatus, setUserSubStatus] = useState(false);

    let cookies = new Cookies();
    let token = cookies.get('token');
    const myRef = useRef(false);

    useEffect(() => {
        updateUserData();
        // check if all field is filled by the user
        checkForMissingData();
        checkUserSubStatus();
    }, []);

    useEffect(() => {
        if (myRef.current) {
            myRef.current.scrollIntoView()
        }
    }, [myRef.current])


    function downloadPDF(sub_id, document_id, typeName) {
        setLoadingData(true);
        const downloadStepCompleted = props.user.download_step_completed;
        if (sub_id) {
            // Request API.
            axios.get(`${Config.apiURL}/generate/${props.user.id}/${sub_id}/${document_id}`, {
                responseType: 'blob',
                headers: {
                    Authorization:
                        `Bearer ${token}`,
                }
            }).then(blob => {
                axios.post(Config.apiURL + '/updateuser', {
                    headers: {
                        Authorization:
                            `Bearer ${token}`,
                    },
                    id: props.user.id,
                    dataType: "download_step"
                }).then(() => {
                    axios.get(Config.apiURL + '/getUser/' + props.user.id).then(response => {
                        props.setUser(response.data);
                    }).then(() => {
                        //Create url from file
                        const fileURL = window.URL.createObjectURL(blob.data);
                        let link = document.createElement('a');
                        link.href = fileURL;
                        link.download = `drwebshop_${typeName}_${props.user.additional_data.company_name}.pdf`;
                        link.setAttribute("target", "_blank");
                        link.click();
                        setLoadingData(false);
                        if (!downloadStepCompleted) props.setActiveKey("dashboard");
                    }).catch(err => {
                        console.log('err', err);
                    });
                }).catch(error => {
                    // Handle error.
                    console.log('An error occurred:', error);
                });
            }).catch(error => {
                // Handle error.
                console.log('An error occurred:', error.response);
            });
        } else {
            console.log('Not impelmented')
        }
    };

    function generateEmbedCode() {
        setLoadingData(true);
        axios.post(Config.apiURL + '/updateuser', {
            headers: {
                Authorization:
                    `Bearer ${token}`,
            },
            id: props.user.id,
            dataType: "embed_step"
        }).then(() => {
            axios.get(Config.apiURL + '/getUser/' + props.user.id).then(response => {
                props.setUser(response.data);
            }).then(() => {
                setLoadingData(false);
                props.setActiveKey("embed_code");
            })
        }).catch(error => {
            // Handle error.
            console.log('An error occurred:', error);
        });
    }

    function updateUserData() {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        axios.get(`${Config.apiURL}/subscription-types`, config).then(response => {
            setSubTypes(response.data)
        }).catch(err => {
            console.log('err', err);
        });
    }

    function getDownloadAbleDocuments() {

        return props.user.subscriptions.map(sub => {
            let subtype = subTypes.find(x => x.id === sub.subscription_type.id);

            if (subtype) {
                const documentTypes = subtype.document_types.map(documentTypes => documentTypes.type);

                const documentTypesList = subtype.document_types.map(type => {
                    let typeName = type.type;

                    return (
                        <div>
                            <p>{type.Name}</p>
                            <button onClick={() => downloadPDF(sub.id, type.id, typeName)} disabledl={isDataMissing || !userSubStatus}>Letöltés</button>
                            <button onClick={() => navigate("/app/profileedit", { state: { typeName } })}>{isDataMissing ? 'Adatok kitöltése' : 'Adatok szerkesztése'}</button>
                        </div>
                    );
                });

                if (!documentTypes.includes('at')) {
                    return (
                        <div className="download-document">
                            {documentTypesList}

                            <div>
                                <p>Adatkezelési Tájékoztató</p>
                                <span>Csak előfizetés esetén érhető el</span>
                                <button style={{marginTop: '16px'}} onClick={() => {
                                    props.setSubModalShow(true);
                                }}>Előfizetés</button>
                            </div>
                        </div>
                    );
                } else {
                    return <div className="download-document">{documentTypesList}</div>;
                }

            } else {
                return (
                    <div style={{ padding: '50px' }}>
                        <p>Jelenleg nem sikerült betölteni a dokumentumait. </p>
                    </div>
                )
            }
        })
    }

    function getEmbedContent() {
        return props.user.subscriptions.map(sub => {
            let subtype = subTypes.find(x => x.id === sub.subscription_type.id);
            let subId = sub.id;
            if (subtype) {
                return (
                    <div className="preview-document">
                        {subtype.document_types.map(type => {
                            return (
                                <div>
                                    <p>{type.Name}</p>
                                    <button style={{ marginRight: '10px' }} onClick={() => setPreview({ sub: subId, type: type.id })} disabledl={isDataMissing}>Előnézet</button>
                                    <button onClick={() => generateEmbedCode()} disabled={isDataMissing}>Beágyazó kód generálása</button>
                                </div>
                            );
                        })}
                    </div>
                );
            } else {
                return (
                    <div style={{ padding: '50px' }}>
                        <p>Jelenleg nem sikerült betölteni a dokumentumait. </p>
                    </div>
                )
            }
        })
    }

    function getPreview() {
        const { hostname } = new URL(props.user.additional_data.webshop_domain);
        let url = `${Config.url}/app/embed/${preview.sub}/${props.user.id}/${hostname}/${preview.type}`

        return (<iframe src={url} style={{ width: '100%', height: '750px' }}></iframe>);
    }

    function checkUserSubStatus() {
        const subs = props.user.subscriptions;
        for (let sub of subs) {
            if (sub.subscription_status.name === "Aktiv") setUserSubStatus(true);
        }
    }

    /**
     * Currently checks if all required fields are filled by the user.
     * If so, the download, embed, preview buttons are enabled.
     * 
     * TODO: separate check of required fields for ászf and at.
     */
    function checkForMissingData() {
        let isMissing = false;


        if (props.user.additional_data !== null) {
            // reused code 
            //todo remove when strapi is okay
            let temp = Object.entries(props.user.additional_data).filter(([key, value]) => {
                if (
                    key !== "id" &&
                    key !== "company_electric_invoice" &&
                    key !== "webshop_einvoice" &&
                    key !== "webshop_registration" &&
                    key !== "webshop_electric_invoice" &&
                    key !== "company_authority_license" &&
                    key !== "testEnum" && //TODO: ez a strapi egy szar rég nincsenek ezek benne már
                    key !== "webshop_confirmation" &&
                    key !== "company_mail_address"
                ) {
                    return true;
                } else {
                }
            });

            temp.forEach(([key, value]) => {
                if (user_data[key]?.type === 'string' && user_data[key].visible) {
                    if (value === null || value === '') {
                        isMissing = true;
                    }
                }
                if (user_data[key]?.type === 'object' && user_data[key].visible) {
                    if (value !== null) {
                        Object.entries(value).filter(([key, value]) => key !== 'id').map(([oKey, oValue]) => {
                            if (value.avalible) {
                                if (oValue === null || oValue === '') {
                                    isMissing = true;
                                }
                            }
                        })
                    }
                }
                if (user_data[key]?.type === 'list' && user_data[key].visible) {
                    value.map(value => {
                        if (value !== null) {
                            Object.values(value).map(x => {
                                if (x === '') {
                                    isMissing = true;
                                }
                            })
                        }
                    })
                }
            })
        }

        setIsDataMissing(isMissing);
    }


    return (
        <Container className="documents-section">
            <div className="documents-title">
                <h4>Dokumentumaim</h4>
            </div>
            <Row className="documents-container">
                {props.user.embed_code_step_completed ?
                    <Col className="download" lg={4} xs={12}>
                        <h6 className="title">Letölthető dokumentumok</h6>
                        {!loadingData ? getDownloadAbleDocuments() :
                            <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
                                <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>}
                    </Col> : null}
                <Col className="preview" lg={7} xs={12}>
                    <h6 className="title">Dokumentumok beágyazása</h6>
                    {!loadingData ? getEmbedContent() :
                        <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
                            <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </div>}
                </Col>
            </Row>
            {preview && <div className="preview-container" ref={myRef}>
                <FontAwesomeIcon icon={faTimes} onClick={() => setPreview(false)} role="button" />
                <h4 className="preview-header">Előnézet</h4>
                {getPreview()}
            </div>}
        </Container>
    );
}

const mapStateToProps = (state) => ({
    user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
    setUser: (user) => {
        dispatch(setUser(user))
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentsTab)
