export let user_data = {
    company_name:{type:'string', name:'Céged, vállalkozásod rövid neve' ,visible:true },
    email:{value: '', type: 'string', name: 'A regisztrációhoz használt e-mail', help:'Nem szerkeszthető mező' },
    company_address: {type:'string', name:'Céged, vállalkozásod székhelye' ,visible:true },
    company_mail_address:{type:'string', name:'Levelezési cím' ,visible:true },
    company_registration_authority:{type:'string', name:'Cégedet, vállalkozásodat nyilvántartásba vevő bíróság vagy hatóság' ,visible:false },
    company_authority_license:{type:'object', name:'Nyilvántartási szám' ,visible:false },
    company_tax_number:{type:'string', name:'Vállalkozásom adószáma' ,visible:true },
    company_representative:{type:'string', name:'Különleges hatósági engedély ' ,visible:false },
    company_registration_number:{type:'string', name:'A vállalkozás képviselője' ,visible:false },
    company_bank_account_number:{ type:'list', name:'Bankszámla szám (ok)' ,visible:true },
    webshop_type:{type:'string', name:'Milyen szoftveres megoldást használ a webshopod?' ,visible:false },
    webshop_domain:{type:'string', name:'Webshopom domain neve' ,visible:true },
    webshop_registration:{type:'string', name:'Van regisztráció az oldaladon? A regisztráció kötelező?' ,visible:false },
    webshop_submit_button:{type:'string', name:'Cégedet, vállalkozásodat nyilvántartásba vevő bíróság vagy hatóság' ,visible:false },
    webshop_confirmation:{type:'string', name:'Hogyan igazolod vissza a megrendelést?' ,visible:false },
    webshop_electric_invoice:{value:'false', name:'Elektronikus számlát bocsátasz ki?' ,visible:false },
    general_phone:{type:'list', name:'Telefonszámunk' ,visible:true },
    general_email:{type:'list', name:'Email címünk',visible:true },
    general_customer_service:{type:'object', name:'Személyes ügyfélszolgálatunk (pl. üzlet)' ,visible:false },
    general_notification_emails:{type:'list', name:'Hova küldjünk neked DrWebshop rendszerüzenetet?' ,visible:false },
};