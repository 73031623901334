import React from "react";
import { navigate } from "gatsby";
import { user_data } from '../../../_helpers/userdata';
import { Card } from "react-bootstrap";

export default function ProfileTab(props) {

    function setupAdditionalUserData() {
        //temporary solution strapi has some pretty shit stuff going o
        if (!props.user.additional_data_filled) {
            return (
                <div className="missing-additional-data">
                    <p>Hiányzó felhasználó adatok. Kérem töltse ki őket a csomagválasztás megkezdéséhez.</p>
                    <button onClick={() => navigate("/app/profileedit")}>Adatok kitöltése</button>
                </div>
            )
        } else {
            let temp = Object.entries(props.user.additional_data).filter(([key, value]) => {
                if (key !== 'id' && key !== 'company_electric_invoice' && key !== 'webshop_einvoice' && key !== 'testEnum' && !key.startsWith("company_address")) {
                    return true
                } else {
                    return false
                }
            });
            temp.push(['company_address', `${props.user.additional_data.company_address_postcode} ${props.user.additional_data.company_address_city} ${props.user.additional_data.company_address_street}`]);
            return temp.map(([key, value], index) => {
                if (user_data[key]?.visible) {
                    if (user_data[key]?.type === 'string') {
                        if (key === 'webshop_domain') {
                            return;
                        }
                        if (index === 5) {
                            return (
                                <>
                                    <div className="user-data-row">
                                        <h6 className="data-name" style={{}}>Regisztrációhoz használt e-mail</h6>
                                        <p className="data-value">{props.user.email}</p>
                                    </div>
                                    <div className="user-data-row">
                                        <h6 className="data-name" style={{}}>{user_data[key].name}</h6>
                                        <p className="data-value">{value !== null && value !== '' ? value : <span style={{ color: '#e65a5a' }}>Még nincs megadva</span>}</p>
                                    </div>
                                </>);
                        } else {
                            return (
                                <div className="user-data-row">
                                    <h6 className="data-name" style={{}}>{user_data[key].name}</h6>
                                    <p className="data-value">{value !== null && value !== '' ? value : <span style={{ color: '#e65a5a' }}>Még nincs megadva</span>}</p>
                                </div>
                            )
                        }
                    }
                    else if (user_data[key].type === 'object') {
                        return (
                            <div className="user-data-row-obj">
                                <h5 className="data-title">{user_data[key].name}</h5>
                                {Object.entries(value).filter(([key, value]) => key !== 'id').map(([oKey, oValue]) => {
                                    return (
                                        <div className="child-user-data-obj">
                                            <h6 className="data-name">{oKey}</h6>
                                            <p className="data-value">{oValue !== null && oValue !== '' ? oValue : <span style={{ color: '#e65a5a' }}>Még nincs megadva</span>}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        )
                    }
                    else if (user_data[key].type === 'list') {
                        return (
                            value.map(value => {
                                Object.values(value).map(x => {
                                    return (<p>{x !== null && x !== '' ? x : <span style={{ color: '#e65a5a' }}>Még nincs megadva</span>}</p>)
                                })
                            })
                        );
                    } else {
                        return (
                            <p>{user_data[key].type}</p>
                        );
                    }
                }
            });
        }
    }

    return (
        <div className="my-profile-section">
            <Card>
                <Card.Header>
                    <div className="header-text">
                        <h3>Profilom</h3>
                    </div>
                </Card.Header>
                <Card.Body>
                    <p className="title">{props.user.additional_data_filled ? props.user.additional_data.company_name ? props.user.additional_data.company_name : props.user.username : props.user.username}</p>
                    <div className="user-data-container">
                        {setupAdditionalUserData()}
                    </div>
                </Card.Body>
            </Card>
            <button onClick={() => navigate("/app/profileedit")}>Adatok módosítása</button>
        </div>
    )
}