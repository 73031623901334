import React from "react";
import { Router } from "@reach/router";
import Profile from "../components/Profile/Profile";
import ProfileEdit from "../components/Profile/ProfileEdit";
import PrivateRoute from "../components/_helpers/PrivateRoute";
import Payment from "../components/Profile/Payment";

//For iframe
import Embed from "../pages/embed";
import DashboardProfileEdit from "../components/Profile/DashboardProfileEdit";

const App = () => {
    return (
        <Router basepath="/app">
            <PrivateRoute path="/profile" component={Profile} />
            <PrivateRoute path="/profileedit" component={ProfileEdit} />
            <PrivateRoute path="/dashboard_profileedit" component={DashboardProfileEdit} />
            <PrivateRoute path="/payment/:status" component={Payment} />
            <Embed path="/embed/:subscriptionid/:userid/:domain/:documentid" />
        </Router>
    )
};

export default App