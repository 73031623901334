import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { navigate } from 'gatsby';
import { propTypes } from 'react-bootstrap/esm/Image';

export default function Subrow({ sub, subType, setDetails, details, setActiveKey }) {

    return(
        <tr className={ details ? details.sub.id === sub.id ? 'selected' : "" : ""}>
            <p>{ sub.subscription_status.name } </p>
            <td>{moment(sub.startDate).format('YYYY-MM-DD hh:mm')}</td>
            <td>{moment(sub.endDate).format('YYYY-MM-DD hh:mm')}</td>
            <td>{sub.subscription_type.label}</td>
            <td>
                <table style={{textAlign:"center", width:'100%'}}>
                    {subType.document_types.map(type => {
                        return(
                            <tr style={{cursor:'pointer'}} onClick={() => setActiveKey("documents")}>
                                <td>{type.Name}</td>
                            </tr>
                        );
                    })}
                </table>
            </td>
            <td><FontAwesomeIcon className="qty-icon minus-icon" style={{cursor:'pointer'}} className="help-icon" onClick={() => setDetails({sub:sub, subType:subType})} icon={faEdit}/></td>
        </tr>
    );
}